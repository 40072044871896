import React, { useState } from 'react'
import styles from './ActionButtons.module.scss'
import { ActionButton } from './ActionButton'
import share from '../../../core/Icon/icons/svg/qrshare.svg'
import arrowDown from '../../../core/Icon/icons/svg/Chevron_Down.svg'
import useStore, { Page } from '../store'
import { DOCTOR_PROTOCOL, PATIENT_PROTOCOL } from '../../../../utils/constants'
import { QRCodeModel } from '../../InteractionSummary/QRCodeModel'
import {
  generateMultipleQRCodes,
  generateQRCodeData,
} from '../../InteractionSummary/QRCodeGenerator'
import QrModalShared from '../../../shared/QrModalShared'
import { useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ActionMenu } from '../../../core/MoreMenu/ActionMenu'
import { ICON_TYPE } from '../../../core/Icon/Icon'
import MarkdownIt from 'markdown-it'
import useQrCodeData from '../../../../hooks/useQrCodeData'
import useToast from '../../../../hooks/useToast'

export const ActionButtons: React.FC = () => {
  const { t } = useTranslation()
  const showToast = useToast((state) => state.showToast)
  const editedSummaries = useStore((state) => state.editedSummaries)
  const page = useStore((state) => state.page)
  const { getQrData, qrDataAvailable } = useQrCodeData()
  const isHistoryPage = useMatch('/history')
  const isConsultationPage = useMatch('/consultation')

  const [qrModalState, setQrModalState] = useState<{
    show: boolean
    data: string | null
    dataStream: string[] | null
  }>({
    show: false,
    data: null,
    dataStream: null,
  })

  const [error, setError] = useState<string | null>(null)

  const onSubmit = async () => {
    const qr = getQrData()
    handleGenerateQRCode(qr, false)
  }

  const handleGenerateQRCode = async (data: QRCodeModel, qrStream: boolean) => {
    try {
      if (qrStream) {
        const generatedData = await generateMultipleQRCodes(data, 500)
        setQrModalState({ show: true, data: null, dataStream: generatedData })
      } else {
        const generatedData = await generateQRCodeData(data)
        setQrModalState({ show: true, data: generatedData, dataStream: null })
      }
      setError(null)
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        console.log(err)
        if (err.name === 'QRCodeCapacityError') {
          showToast('danger', t('consult.manual.qr-code.capacity-error', 
            'Content is too large for a single QR code. Try one of the following:\n' +
            '- Reduce the description length\n' +
            '- Remove some attachments\n' +
            '- Use multiple QR codes instead'
          ))
        } else {
          showToast('danger', t('consult.manual.qr-code.general-error',
            'Error generating QR Code. Please try again.'
          ))
        }
      }
      setError(
        t(
          'qr-code.error.while-generating',
          'Error generating QR Code. Please try again.',
        ),
      )
    }
  }

  const handleQRModalClose = () => {
    setQrModalState({ show: false, data: null, dataStream: null })
  }

  const pagesWithButtons = [Page.aiRecordingSummary, Page.newManual]
  const showButtons =
    (isConsultationPage && pagesWithButtons.includes(page)) || isHistoryPage

  function markdownToPlainText(markdown: string) {
    const md = new MarkdownIt()
    const html = md.render(markdown)
    return html.replace(/<[^>]+>/g, '') // Strip HTML tags
  }

  function copyToClipboard(
    type: typeof PATIENT_PROTOCOL | typeof DOCTOR_PROTOCOL,
  ) {
    //
    const text =
      editedSummaries
        .find((s) => s.protocol_name === type)
        ?.content?.map((c) => {
          const titleMap = {
            S: t('consult.report.title.subjective', 'Subjective'),
            O: t('consult.report.title.objective', 'Objective'),
            A: t('consult.report.title.assessment', 'Assessment'),
            P: t('consult.report.title.plan', 'Plan'),
            SUMMARY: t('consult.report.title.summary', 'PATIENT REPORT'),
          }
          return ` ${titleMap[c.field_name as keyof typeof titleMap]}: ${markdownToPlainText(c.content)}`
        })
        .join(' ') || ''
    //
    navigator.clipboard.writeText(text)
  }
  return (
    <>
      {showButtons ? (
        <div className={styles.container}>
          <ActionMenu
            isButton={false}
            actions={[
              {
                label: t('copy.medical', 'Medical report'),
                icon: ICON_TYPE.CROSS,
                onClick: () => copyToClipboard(DOCTOR_PROTOCOL),
              },
              {
                label: t('copy.patient', 'Patient report'),
                icon: ICON_TYPE.PATIENT,
                onClick: () => copyToClipboard(PATIENT_PROTOCOL),
              },
            ]}
          >
            <ActionButton
              key={0}
              label={t('common.copy-report', 'Copy report')}
              icon={arrowDown}
              variant="secondary"
              onClick={() => {}}
            />
          </ActionMenu>
          <ActionButton
            key={1}
            label={t('common.share', 'Share')}
            icon={share}
            variant="primary"
            onClick={onSubmit}
            disabled={!qrDataAvailable}
          />
        </div>
      ) : (
        <div />
      )}
      <QrModalShared
        close={handleQRModalClose}
        show={qrModalState.show}
        data={qrModalState.data}
        dataStream={qrModalState.dataStream}
      />
    </>
  )
}
