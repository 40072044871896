import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Cookies from 'js-cookie'
import { COOKIE_CONSENT } from '../../Auth/Login/CookieConsentModal'

const CookieSettings = () => {
  const { t } = useTranslation()
  const [valueFromCookie, _] = useState(Cookies.get(COOKIE_CONSENT) === 'true')
  const [useCookie, setUseCookie] = useState(valueFromCookie)

  const onChange = (cookie: boolean) => {
    setUseCookie(cookie)
    Cookies.set(COOKIE_CONSENT, cookie.toString())
  }

  return (
    <div className="settings-row">
      <div className="description">
        <div className="title">
          {t('settings.privacy.cookies', 'Cookie Settings')}
        </div>
        <div className="subtext">
          {t(
            'settings.privacy.cookies.description',
            'These cookies help us improve our services by collecting anonymous usage data.',
          )}
        </div>
      </div>
      <Form.Check
        className="settings-radio-option"
        type="radio"
        id="cookie-accept"
      >
        <Form.Check.Input
          type="radio"
          name="cookie"
          checked={useCookie}
          onChange={() => onChange(true)}
        />
        <Form.Check.Label>
          {t('settings.privacy.cookies.accept', 'Accept all cookies')}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check
        className="settings-radio-option"
        type="radio"
        id="cookie-reject"
      >
        <Form.Check.Input
          type="radio"
          name="cookie"
          checked={!useCookie}
          onChange={() => onChange(false)}
        />
        <Form.Check.Label>
          {t('settings.privacy.cookies.reject', 'Reject non-essential cookies')}
        </Form.Check.Label>
      </Form.Check>
    </div>
  )
}

export default CookieSettings
