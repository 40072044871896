import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
import './App.scss'
import FaqContainer from './components/features/Settings/Faq/FaqContainer'
import { NavigationPanel } from './components/features/NavigationPanel/NavigationPanel'
import OrganizationPage from './components/features/Organization/OrganizationPage'
import OrganizationRegistration from './components/features/Organization/OrganizationRegistration'
import TermsPage from './components/features/Terms/TermsPage'
import RegistrationPage from './components/RegistrationPage'
import { Header } from './components/shared/Layout/Header'
import ContentLibraryPage from './pages/ContentLibraryPage'
import ConsultationPage from './pages/ConsultationPage'
import LandingPage from './pages/LandingPage'
import HistoryPage from './pages/HistoryPage'
import SettingsPage from './pages/SettingsPage'
import ElectronChecker from './components/ElectronListener'

// Layout components
const UnauthenticatedLayout: React.FC = () => (
  <main className="app-main">
    <Outlet />
  </main>
)

const AuthenticatedLayout: React.FC = () => (
  <>
    <ElectronChecker>
      <Header />
      <NavigationPanel />
      <main className="app-main">
        <Outlet />
      </main>
    </ElectronChecker>
  </>
)

// Route guards
const ProtectedRoutes: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

const PublicRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    return <Navigate to="/consultation" replace />
  }

  return <Outlet />
}

// Define routes
const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicRoutes />,
    children: [
      { path: '/', element: <LandingPage /> },
      { path: '/register', element: <RegistrationPage /> },
      { path: '/terms', element: <TermsPage /> },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
  {
    path: '/',
    element: <ProtectedRoutes />,
    children: [
      {
        element: <AuthenticatedLayout />,
        children: [
          { path: '/consultation', element: <ConsultationPage /> },
          { path: '/settings', element: <SettingsPage /> },
          { path: '/support/faq', element: <FaqContainer /> },
          { path: '/library', element: <ContentLibraryPage /> },
          { path: '/organization', element: <OrganizationPage /> },
          {
            path: '/organization/register',
            element: <OrganizationRegistration />,
          },
          { path: '/history', element: <HistoryPage /> },
          { path: '*', element: <Navigate to="/consultation" replace /> },
        ],
      },
    ],
  },
])

const App: React.FC = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Container fluid className="vh-100">
      <RouterProvider router={router} />
    </Container>
  )
}

export default App
