import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const LegalLinks: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="d-flex gap-300 fs-12">
      <Link to="/terms" className="text-gray-700" target="_blank">
        {t('common.terms&conditions', 'Terms & conditions')}
      </Link>
      <Link to="/privacy-policy" className="text-gray-700" target="_blank">
        {t('common.privacy.policy', 'Privacy policy')}
      </Link>
      <Link to="/cookie-policy" className="text-gray-700" target="_blank">
        {t('common.cookie.policy', 'Cookie policy')}
      </Link>
    </div>
  )
}

export default LegalLinks
