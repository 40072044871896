import React, { useEffect, useRef, useState } from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { DataTransferType, useDragAndDropStore } from "../../hooks/useDragAndDrop/useDragAndDrop";
import attachment_link_icon from "../core/Icon/icons/svg/drag_attachment_link.svg";
import template_icon from "../core/Icon/icons/svg/drag_template.svg";

type DragAndDropOverlayProps = {
    handleOnDrop?: (dropEvent: React.DragEvent) => void;
    containerRef: React.RefObject<HTMLDivElement>
}

const DragAndDropOverlay: React.FC<PropsWithChildren<DragAndDropOverlayProps>> = ({ children, handleOnDrop, containerRef }) => {
    const drag = useDragAndDrop();
    const { t } = useTranslation();
    const [scrollOffset, setScrollOffset] = useState(0)

    let icon;
    let label = '';
    switch (drag.dataTransferType) {
        case DataTransferType.Attachment:
            icon = attachment_link_icon;
            label = t('drag.drop.attachment', 'Attach link to this report');
            break;
        case DataTransferType.Template:
            icon = template_icon;
            label = t('drag.drop.attachment', 'Add template to this report');
            break;
        default:
            icon = '';
            label = t('drag.drop.here', 'Drop item here');
            break;
    }

    useEffect(() => {   
    const onScroll = () => {
        if(containerRef.current) {
            setScrollOffset(containerRef.current.scrollTop)
        } else {
            setScrollOffset(0)
        }
    }
    containerRef.current?.addEventListener('scroll', onScroll)
    return () => {
        containerRef.current?.removeEventListener('scroll', onScroll)
    }
    }, [containerRef.current])

    return (
        <div
            className="drag-drop-overlay w-100"
            onDragEnter={drag.handleDragEnter}
            onDragLeave={drag.handleDragLeave}
            onDragOver={drag.handleOnDragOver}
            onDrop={drag.handleDrop}
            ref={drag.containerRef}
        >
            {drag.isDragInProgress && (
                <div className="overlay" style={{top: `${scrollOffset}px`}} ref={drag.overlayRef}>
                    <div className="overlay-icon">
                        <img
                            alt="drag and drop overlay icon"
                            src={icon}
                        />
                    </div>
                    <div className="overlayLabel">
                        {label}
                    </div>
                </div>
            )}
            <div className="content">
                {children}
            </div>
        </div>
    );
}

const useDragAndDrop = () => {
    const isDragInProgress = useDragAndDropStore(state => state.isDragInProgress);
    const dataTransferType = useDragAndDropStore(state => state.dataTransferType);
    const dragStart = useDragAndDropStore(state => state.dragStart);
    const dragStop = useDragAndDropStore(state => state.dragStop);
    const drop = useDragAndDropStore(state => state.drop);

    const debounce = useRef<any>(null);
    const overlayRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const isDraggingOver = useRef(false);

    const handleDragEnter = (e: React.DragEvent) => {
        if (debounce.current) {
            clearTimeout(debounce.current);
        }
        
        let dtt = DataTransferType.None;
        if (e.dataTransfer.types.includes('attachment')) {
            dtt = dtt | DataTransferType.Attachment;
        }

        if(dtt != DataTransferType.None) {
            e.preventDefault();
            debounce.current = setTimeout(() => {
                if (!isDraggingOver.current) {
                    dragStart(dtt);
                    isDraggingOver.current = true;
                };
            }, 30);
        }
    };
    
    const handleOnDragOver = (e: React.DragEvent) => {
        if(isDragInProgress) {
            e.preventDefault();
        }
    }

    const handleDragLeave = (e: React.DragEvent) => {
        if (!(containerRef.current && overlayRef.current)) {
            return;
        }

        if (!containerRef.current.contains(e.relatedTarget)
            && !overlayRef.current.contains(e.relatedTarget)) {
            e.preventDefault();
            dragStop();
            isDraggingOver.current = false;
        }
    };

    const handleDrop = (e: React.DragEvent) => {
        if(isDragInProgress) {
            e.preventDefault();
            drop(e);
            isDraggingOver.current = false;
        }
    };

    useEffect(() => {
        return () => {
            if (debounce.current) {
                clearTimeout(debounce.current);
            }
        };
    }, []);

    return { isDragInProgress, handleDragEnter, handleOnDragOver, handleDragLeave, handleDrop, overlayRef, containerRef, dataTransferType };
}

export default DragAndDropOverlay;