import React from 'react'
import styles from './MicrophonePermission.module.scss'
import { useTranslation } from 'react-i18next'

export const MicrophonePermission: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.container}
      role="dialog"
      aria-labelledby="permission-title"
    >
      <div id="permission-title" className={styles.title}>
        {t('allow-use-mic', 'Please allow Ditto to use your microphone!')}
      </div>
      <div className={styles.description}>
        <ol>
          <li>
            {t(
              'consult.microphone-access-blocked.modal-description-step-1',
              "Click the lock icon in your browser's address bar.",
            )}
          </li>
          <li>
            {t(
              'consult.microphone-access-blocked.modal-description-step-2',
              'Find "Microphone" permissions.',
            )}
          </li>
          <li>
            {t(
              'consult.microphone-access-blocked.modal-description-step-3',
              'Select "Allow" and refresh the page.',
            )}
          </li>
        </ol>
      </div>
    </div>
  )
}
