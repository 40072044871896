import React, { useEffect, useRef, useState } from 'react'
import mountains from '../components/core/Icon/icons/png/mountains.png'
import ArrowMoveLeft from '../components/core/Icon/icons/svg/Chevron_Left_Duo.svg'
import ConsultConfig from '../components/features/Consult/ConsultConfig'
import useStore, { Page } from '../components/features/Consult/store'
import AiConsult from '../components/features/Consult/AiConsult'
import ManualConsult from '../components/features/Consult/ManualConsult'
import { AudioRecorder } from '../components/features/Consult/InputDeviceForm/AudioRecorder'
import RecordingInProgress from '../components/features/Consult/InputDeviceForm/RecordingInProgress'
import { ChatContainer } from '../components/features/Consult/Transcript/ChatContainer'
import { SoapReport } from '../components/features/Consult/Report/SoapReport'
import { DOCTOR_PROTOCOL, PATIENT_PROTOCOL } from '../utils/constants'
import SummaryPage from './SummaryPage'
import useClearConsultData from '../hooks/Ai/useClearConsultData'
import { useTranslation } from 'react-i18next'
import DragAndDropOverlay from '../components/shared/DragAndDropOverlay'
import useToast from '../hooks/useToast'
import { Toast } from '../components/core/Toast/Toast'

const ConsultationPage: React.FC = () => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedRight, setCollapsedRight] = useState(false)
  const page = useStore((state) => state.page)
  const [isDoctorProtocol, setIsDoctorProtocol] = useState(
    page !== Page.newManual,
  )
  const toast = useToast((state) => state.toast)
  const pageContainerRef = useRef<HTMLDivElement>(null)
  useClearConsultData()

  useEffect(() => {
    if (page === Page.newManual) {
      setIsDoctorProtocol(false)
    }
  }, [page])

  const getView = () => {
    const views = [
      <ConsultConfig />,
      <AiConsult />,
      <ManualConsult />,
      null,
      <AiConsult />,
    ]
    return views[page]
  }

  const showProtocolSelector =
    page === Page.aiRecordingSummary || page === Page.newManual
  const showPatientOnly = page === Page.newManual

  return (

    <div className="d-flex h-100">
      {toast.show && <Toast variant={toast.variant} message={toast.message} />}
      <div
        className={`consult-left-column ${page === Page.aiRecordingInProgress ? 'd-none' : 'd-flex'} flex-column ${collapsed ? 'collapsed' : ''}`}
      >
        <div className="consult-head justify-content-between">
          <span className="collapsable">
            {page === Page.newAi || page === Page.aiRecordingSummary
              ? t('consult.consult.ai-header', 'AI-enhanced consultation')
              : page === Page.newManual
              ? t('consult.consult.manual-header', 'Standard consultation')
              : t('consult.consult.select-type', 'Select consultation type')}
          </span>
          {page === Page.aiRecordingSummary || page === Page.newManual ? (
            <img
              alt="arrow move"
              src={ArrowMoveLeft}
              className={`cursor-pointer ${collapsed ? 'mirror-x' : ''}`}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : null}
        </div>
        <span className="consult-head-label collapsable">
          {page === Page.newAi || page === Page.aiRecordingSummary
            ? t('consult.consult.ai-header', 'AI-enhanced consultation')
            : page === Page.newManual
            ? t('consult.consult.manual-header', 'Standard consultation')
            : t('consult.consult.select-type', 'Select consultation type')}
        </span>
        <div className="consult-left-column-content flex-grow-1 collapsable">
          {getView()}
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-column">
        <div
          className={`${page === Page.aiRecordingInProgress ? 'd-none' : 'justify-content-center align-items-center consult-head'}`}
        >
          {showProtocolSelector && (
            <>
              {!showPatientOnly && (
                <div
                  onClick={() => setIsDoctorProtocol(true)}
                  className={`cursor-pointer consult-head-menu ${isDoctorProtocol ? 'consult-head-menu-active' : ''}`}
                >
                  {t('consult.tab.medical', 'Medical')}
                </div>
              )}
              {page !== Page.newManual &&
                <div
                onClick={() => setIsDoctorProtocol(false)}
                className={`cursor-pointer consult-head-menu ${isDoctorProtocol ? '' : 'consult-head-menu-active'}`}
              >
                {t('consult.tab.patient', 'Patient')}
              </div>
              }
              
            </>
          )}
        </div>
        <div
          className={`consult-right-column-content flex-grow-1 d-flex
           ${page === Page.aiRecordingSummary || page === Page.newManual ? '' : 'justify-content-center align-items-center'}`}
           ref={pageContainerRef}
        >
          {page === Page.newAi ? (
            <AudioRecorder />
          ) : page === Page.aiRecordingInProgress ? (
            <RecordingInProgress />
          ) : page === Page.aiRecordingSummary ? (
            <SoapReport
              type={isDoctorProtocol ? DOCTOR_PROTOCOL : PATIENT_PROTOCOL}
            />
          ) : page === Page.newManual ? (
            <DragAndDropOverlay containerRef={pageContainerRef}>
              <SummaryPage />
            </DragAndDropOverlay>
          ) : (
            <div className="consult-right-dummy-content d-flex flex-column align-items-center ">
              <img alt="" src={mountains} />
              <span className="consult-right-dummy-content-text">
                {t('consult.content.dummy','Please select consultation type first')}
              </span>
            </div>
          )}
        </div>
      </div>
      {page === Page.aiRecordingSummary && (
        <div
          className={`consult-right-end-column d-flex flex-column ${collapsedRight ? 'collapsed' : ''}`}
        >
          <div className="consult-head justify-content-between">
            <span className="collapsable">
              {t('consult.transcript.header', 'Transcript')}
            </span>
            {page === Page.aiRecordingSummary ? (
              <img
                className={`cursor-pointer ${collapsedRight ? '' : 'mirror-x'}`}
                onClick={() => setCollapsedRight(!collapsedRight)}
                alt="arrow move"
                src={ArrowMoveLeft}
              />
            ) : null}
          </div>
          <span className="consult-head-label consult-head-label-right collapsable">
            {t('consult.transcript.header', 'Transcript')}
          </span>
          <div
            className={`consult-right-end-column-content flex-grow-1 ${collapsedRight ? 'collapsable' : ''}`}
          >
            {<ChatContainer />}
          </div>
        </div>
      )}
    </div>
  )
}

export default ConsultationPage
