import { Organization, Result } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthMutationDelete,
  useAuthMutationPost,
  useAuthQuery,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = `${getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL') || ''}/organizations`

export const useOrganization = () => {
  return useAuthQuery<Result<Organization>>(['organization'], {
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useUpsertOrganization = () => {
  return useAuthMutationPost<
    Result<Organization>,
    Error,
    Partial<Organization>
  >({
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useDeleteOrganization = (orgId: string) => {
  return useAuthMutationDelete<Result<void>, Error>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}`,
  })
}

export const useRemoveUserFromOrganization = (
  orgId: string,
  userId: string,
) => {
  return useAuthMutationDelete<Result<void>, Error>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}/users/${userId}`,
  })
}

export const useCreateOrganizationInvitation = (orgId: string) => {
  return useAuthMutationPost<Result<void>, Error, { email: string }>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}/invitations`,
  })
}
