import React from 'react'
import styles from './DittoSettings.module.scss'
import { DescriptionBlock } from './DescriptionBlock'
import { SegmentedButtonsProps } from './types'

export const SegmentedButtons: React.FC<SegmentedButtonsProps> = ({
  title,
  description,
  options,
  onClick,
  isLast = false,
}) => (
  <div className={styles.settingRow} style={isLast ? { border: 'none' } : {}}>
    <DescriptionBlock title={title} description={description} />
    <div className={styles.segmentedButtons}>
      {options.map((option, index) => (
        <button
          key={option.label}
          onClick={() => onClick(option.value)}
          className={`${styles.segmentBtn} ${option.isActive ? styles.active : ''} ${
            index === 0
              ? styles.leftBtn
              : index === options.length - 1
                ? styles.rightBtn
                : styles.middleBtn
          }`}
        >
          {option.label}
        </button>
      ))}
    </div>
  </div>
)
