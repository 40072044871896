import * as pako from 'pako'
import { dataToFrames } from 'qrloop'

const SECRET_KEY = 'ditto_patient_2024_shared_key'

const generateEncryptionKey = async (sharedKey: string): Promise<CryptoKey> => {
  const encoder = new TextEncoder()
  const keyData = encoder.encode(sharedKey)
  const hash = await crypto.subtle.digest('SHA-256', keyData)

  return crypto.subtle.importKey('raw', hash, { name: 'AES-GCM' }, false, [
    'encrypt',
  ])
}

const encryptData = async (
  data: Uint8Array,
  key: CryptoKey,
): Promise<Uint8Array> => {
  const iv = crypto.getRandomValues(new Uint8Array(12))

  const ciphertext = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    data,
  )

  const combined = new Uint8Array(iv.length + ciphertext.byteLength)
  combined.set(iv)
  combined.set(new Uint8Array(ciphertext), iv.length)
  return combined
}

const base64EncodeUint8Array = (arr: Uint8Array): string => {
  let binary = ''
  arr.forEach((byte) => (binary += String.fromCharCode(byte)))
  return btoa(binary)
}

const base64DecodeToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

export class QRCodeCapacityError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'QRCodeCapacityError'
    Object.setPrototypeOf(this, QRCodeCapacityError.prototype)
  }
}

// Adjust the size limit based on QR code library limitations
// Conservative limit to prevent QRCode.js overflow
// QR code type 40 and High error correction => max 10208 bits => 1276 bytes
const QR_CODE_SIZE_LIMIT = 1276

export const generateQRCodeData = async (
  data: Object,
): Promise<string> => {
  try {
    const jsonString = JSON.stringify(data)
    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    const key = await generateEncryptionKey(SECRET_KEY)
    const encryptedData = await encryptData(
      base64DecodeToUint8Array(base64Data),
      key,
    )

    const customUrl = `dittopatient://event?data=${encodeURIComponent(base64EncodeUint8Array(encryptedData))}`
    
    // Check after URL encoding
    if (customUrl.length > QR_CODE_SIZE_LIMIT) {
      throw new QRCodeCapacityError('Final QR code URL exceeds maximum length')
    }
    return customUrl
  } catch (error) {
    if (error instanceof QRCodeCapacityError) {
      throw error
    }
    // Check for QRCode.js specific error
    if (error instanceof Error && error.message.includes('code length overflow')) {
      throw new QRCodeCapacityError('QR code capacity exceeded')
    }
    console.error('Error generating QR code data:', error)
    throw new Error('Failed to generate QR code')
  }
}

const splitDataIntoChunks = (
  data: Uint8Array,
  chunkSize: number,
): Uint8Array[] => {
  let chunks = []
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize))
  }
  return chunks
}

export const generateMultipleQRCodes = async (
  data: Object,
  chunkSize: number,
): Promise<string[]> => {
  try {
    const jsonString = JSON.stringify(data)
    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    // If single QR code fails, suggest using multiple QR codes
    if (base64Data.length > QR_CODE_SIZE_LIMIT) {
      const key = await generateEncryptionKey(SECRET_KEY)
      const encryptedData = await encryptData(
        base64DecodeToUint8Array(base64Data),
        key,
      )

      const dataChunks = splitDataIntoChunks(encryptedData, chunkSize)
      const totalChunks = dataChunks.length
      
      // Verify each chunk will fit in a QR code
      const urls = dataChunks.map((chunk, index) => {
        const chunkString = base64EncodeUint8Array(chunk)
        const url = `dittopatient://event?total=${totalChunks}&chunk=${index}&data=${encodeURIComponent(chunkString)}`
        if (url.length > QR_CODE_SIZE_LIMIT) {
          throw new QRCodeCapacityError('Individual QR code chunk exceeds capacity')
        }
        return url
      })
      
      return urls
    }
    
    throw new Error('Data should be handled by single QR code')
  } catch (error) {
    if (error instanceof QRCodeCapacityError) {
      throw error
    }
    console.error('Error generating multiple QR codes:', error)
    throw new Error('Failed to generate QR codes')
  }
}

export const generateMultipleQRCodeLoop = async (
  data: Object,
): Promise<string[]> => {
  try {
    const jsonString = JSON.stringify(data)
    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    const key = await generateEncryptionKey(SECRET_KEY)
    const encryptedData = await encryptData(
      base64DecodeToUint8Array(base64Data),
      key,
    )
    const frames = dataToFrames(encryptedData.toString())
    return frames
  } catch (error) {
    console.error('Error generating QR code data:', error)
    return []
  }
}
