import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import styles from './AudioRecorder.module.scss'
import OldMic from './../../../core/Icon/icons/OldMic.svg'
import { AudioLevel } from './AudioLevel'
import Button from '../../../core/Button/Button'
import { t } from 'i18next'
import useStore, { Page } from '../store'
import { SelectField } from '../AiConsultForm/SelectField'
import {
  useAiApiQuery,
  useAiMutationPost,
} from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { useTestMode } from '../../../../utils/utils'
import { MicrophonePermission } from './MicrophonePermission/MicrophonePermission'
import { Preferences } from '../../Settings/ProfileSettings/AiSettings/types'

interface DeviceOption {
  value: string
  label: string
}

export const AudioRecorder: React.FC = () => {
  const [showModal, setShowModal] = useState(false) // Control modal visibility
  const approveRecording = useStore((state) => state.approveRecording)
  const deviceId = useStore((state) => state.deviceId)
  const setConsultId = useStore((state) => state.setConsultId)
  const protocol = useStore((state) => state.protocol)
  const setDeviceId = useStore((state) => state.setDeviceId)
  const setPage = useStore((state) => state.setPage)
  const defaultAudioLevels = new Array(8).fill(false)
  const [audioLevels, setAudioLevels] = useState<boolean[]>(defaultAudioLevels)
  const [deviceOptions, setDeviceOptions] = useState<DeviceOption[]>([]) // List of available devices
  const startMutation = useAiMutationPost({
    path: '/consultation/start',
  })
  const { data: dataPreference } = useAiApiQuery<Preferences>(['preference'], {
    path: '/user/preference',
  })
  const isTestMode = useTestMode()
  useEffect(() => {
    retryMicrophoneAccess()
  }, [])

  useEffect(() => {
    if (deviceOptions.length) {
      setDeviceOptions(deviceOptions)
      setDeviceId(deviceOptions[0].value)
    }
  }, [deviceOptions])
  const audioContextRef = useRef<AudioContext | null>(null)
  const analyzerRef = useRef<AnalyserNode | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const nodeRef = useRef<AudioNode | null>(null)

  useEffect(() => {
    let animationFrameId: number | null = null

    const monitorAudio = async () => {
      if (!approveRecording) {
        setAudioLevels([...defaultAudioLevels])
        return
      }

      const constraints: MediaStreamConstraints = {
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        streamRef.current = stream

        const audioContext = new AudioContext()
        audioContextRef.current = audioContext

        const source = audioContext.createMediaStreamSource(stream)
        const analyzer = audioContext.createAnalyser()
        analyzerRef.current = analyzer

        analyzer.fftSize = 256
        const bufferLength: number = analyzer.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        nodeRef.current = source.connect(analyzer)

        const updateMeter = () => {
          if (!approveRecording || !analyzer) {
            return
          }

          analyzer.getByteFrequencyData(dataArray)
          const volume = Math.max(...dataArray)
          const newLevels = [...audioLevels]
          audioLevels.forEach((bar, index) => {
            newLevels[index] = index < volume / 32
          })

          setAudioLevels(newLevels)
          animationFrameId = requestAnimationFrame(updateMeter)
        }

        updateMeter()
      } catch (error) {
        console.error(
          t(
            'consult.error.accessing-audio-devices',
            'Error accessing audio devices:',
          ),
          error,
        )
      }
    }

    monitorAudio()

    return () => {
      try {
        // Cleanup
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId)
        }
        streamRef.current?.getTracks().forEach((track) => track.stop())
        nodeRef.current?.disconnect() // Disconnect the node
        if (audioContextRef.current?.state !== 'closed') {
          audioContextRef.current?.close()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [deviceId, approveRecording])

  const retryMicrophoneAccess = async () => {
    setShowModal(false) // Close the modal
    try {
      // Retry microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      // Stop the microphone stream
      stream.getTracks().forEach((track) => track.stop())

      // Fetch devices again
      const devices = await navigator.mediaDevices.enumerateDevices()
      const audioInputDevices = devices
        .filter((device) => device.kind === 'audioinput')
        .map((device) => ({
          label: device.label || `Microphone ${device.deviceId}`,
          value: device.deviceId,
        }))

      setDeviceOptions(audioInputDevices)
      if (audioInputDevices.length > 0) {
        setDeviceId(audioInputDevices[0].value)
      }
    } catch (error) {
      console.error(
        t(
          'consult.error.microphone-access-retry-failed',
          'Microphone access retry failed:',
        ),
        error,
      )
      setShowModal(true) // Keep modal open if retry fails
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <img
              loading="lazy"
              src={OldMic}
              alt="Audio recording icon"
              className={styles.headerIcon}
            />
          </div>
          <h2 className={styles.title}>
            {t('consult.ready-to-start.label', 'Ready to Start?')}
          </h2>
        </div>

        {!showModal ? (
          <div className={styles.controls}>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>
                {t('consult.current-device.label', 'Current device')}:{' '}
                {deviceId ||
                  t('consult.current-device.none-selected', 'None selected')}
              </label>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>
                {t('consult.input.device.label', 'Input device')}
              </label>
              <div style={{ width: '180px' }}>
                <SelectField
                  isDisabled={!approveRecording}
                  value={deviceId}
                  onChange={setDeviceId}
                  options={deviceOptions}
                />
              </div>
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>
                {t('consult.input.level.label', 'Input level')}
              </label>
              <AudioLevel levels={audioLevels} />
            </div>
          </div>
        ) : (
          <MicrophonePermission />
        )}
        <Button
          className={`w-100 ${styles.submitBtn}`}
          data-testid="new_recording"
          type="submit"
          variant="primary"
          disabled={!approveRecording || !deviceId || !protocol}
          onClick={async () => {
            if (isTestMode) {
              setConsultId(12)
              setPage(Page.aiRecordingInProgress)
              return
            }
            startMutation.mutate(
              {
                ...Object.keys(dataPreference || {}).reduce(
                  (acc, currentValue) => ({
                    ...acc,
                    [currentValue.replace('default_', '')]:
                      dataPreference?.[currentValue as keyof Preferences],
                  }),
                  {},
                ),
                protocol,
              },
              {
                onSuccess: (data) => {
                  setConsultId(
                    (data as { consultation_id: number }).consultation_id,
                  )
                  setPage(Page.aiRecordingInProgress)
                },
                onError: (error) => {
                  console.error('create Error:', error)
                },
              },
            )
          }}
        >
          {t('consult.new.recording.button', 'Start recording')}
        </Button>
      </div>
    </>
  )
}
