import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  useAttachments,
  useDeleteAttachment,
  useUpsertAttachment,
} from '../../../../hooks/useAttachment/useAttachmentBase'
import { useAuth } from '../../../../hooks/useAuth'
import useRole from '../../../../hooks/useRole'
import {
  Attachment,
  LibraryItemType,
  TYPE_TRANSLATIONS,
} from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import ConfirmationModal from '../../../core/ConfirmationModal/ConfirmationModal'
import { SearchInput } from '../../../core/SearchInput/SearchInput'
import useLibraryStore from '../store'
import { VALIDATION_ERROR } from '../types'
import { AttachmentCard } from './AttachmentCard'
import AttachmentManagementModal from './AttachmentManagementModal'

const AttachmentManagement: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isManager } = useRole()
  const queryClient = useQueryClient()

  const {
    attachmentModal,
    openAttachmentModal,
    closeAttachmentModal,
    deleteModal,
    openDeleteModal,
    closeDeleteModal,
    showToast,
  } = useLibraryStore()

  const { data: attachments = [], isLoading } = useAttachments()
  const { mutateAsync: upsertAttachment } = useUpsertAttachment()
  const { mutateAsync: deleteAttachment } = useDeleteAttachment()

  const [searchQuery, setSearchQuery] = useState('')
  const [isForOrganization, setIsForOrganization] = useState(false)

  const handleSubmit = async (data: Partial<Attachment>) => {
    try {
      if (attachmentModal.editItem?.id) {
        const id = attachmentModal.editItem.id
        await upsertAttachment({
          ...data,
          displayName: data.displayName?.trim(),
          id,
        })
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })
        showToast(
          'success',
          t(
            'library.attachment.toast.update.success',
            'Attachment updated successfully',
          ),
        )
        closeAttachmentModal()
      } else {
        await upsertAttachment({
          ...data,
          displayName: data.displayName?.trim(),
        })
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })
        showToast(
          'success',
          t(
            'library.attachment.toast.create.success',
            'Attachment created successfully',
          ),
        )
        closeAttachmentModal()
      }
    } catch (error: any) {
      if (error.response?.data?.error === VALIDATION_ERROR) {
        return {
          message: error.response?.data?.message ?? '',
          details: error.response?.data?.details ?? {},
        }
      }
      console.error('Error handling attachment:', error)
      const errorMessage =
        error.details?.message ||
        error.message ||
        t(
          'library.attachment.toast.operation.error',
          'Operation failed. Please try again.',
        )
      showToast('danger', errorMessage)
    }
  }

  const handleEdit = (attachment: Attachment) => {
    openAttachmentModal(attachment)
  }

  const handleDelete = async (id: string) => {
    openDeleteModal(id, 'attachment')
  }

  const handleConfirmDelete = async () => {
    if (deleteModal.itemToDelete?.id) {
      try {
        await deleteAttachment({ id: deleteModal.itemToDelete.id })
        const updatedAttachments = attachments.filter(
          (attachment) => attachment.id !== deleteModal.itemToDelete?.id,
        )
        queryClient.setQueryData(['attachments'], updatedAttachments)
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })

        showToast(
          'success',
          t(
            'library.attachment.toast.delete.success',
            'Attachment deleted successfully',
          ),
        )
        closeDeleteModal()
      } catch (error: any) {
        console.error('Error deleting attachment:', error)
        const errorMessage =
          error.details?.message ||
          error.message ||
          t(
            'library.attachment.toast.delete.error',
            'Failed to delete attachment',
          )
        showToast('danger', errorMessage)
      }
    }
  }

  const filteredAttachments = searchQuery
    ? attachments?.filter?.((attachment) =>
        [attachment.description, attachment.displayName, attachment.url]
          .filter(Boolean) // Remove undefined/null values
          .some((field) =>
            field?.toLowerCase().includes(searchQuery.toLowerCase()),
          ),
      ) || []
    : attachments || []

  const groupAttachments = (attachments: Attachment[]) => {
    const initialGroups = Object.keys(TYPE_TRANSLATIONS).reduce(
      (acc, type) => {
        acc[type as LibraryItemType] = []
        return acc
      },
      {} as Record<LibraryItemType, Attachment[]>,
    )

    return attachments.reduce((acc, attachment) => {
      acc[attachment.type].push(attachment)
      return acc
    }, initialGroups)
  }

  const groupedAttachments = groupAttachments(filteredAttachments)

  return (
    <div className="content-library__content">
      <div className="content-library__content-header">
        <div className="search">
          <SearchInput onChange={setSearchQuery} value={searchQuery} />
        </div>
        <div className="add-button">
          <Button
            icon="plus"
            onClick={() => openAttachmentModal(undefined)}
            variant="secondary"
          >
            {t('library.attachment.add-button', 'Add Attachment')}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mh-300px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="content-library__content-section">
          {Object.entries(groupedAttachments).map(([type, attachments]) => (
            <div
              key={type}
              className="content-library__content-section__wrapper"
            >
              <div className="content-library__content-section__title">
                {t(TYPE_TRANSLATIONS[type as LibraryItemType])}
              </div>
              {attachments.length > 0 ? (
                <div className="content-library__content-section__content">
                  {attachments.map((attachment) => (
                    <AttachmentCard
                      key={attachment.id}
                      attachment={attachment}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">
                  {t(
                    'library.type.empty-group',
                    'No {{type}} attachments available',
                    {
                      type: t(
                        TYPE_TRANSLATIONS[type as LibraryItemType],
                      ).toLowerCase(),
                    },
                  )}
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <AttachmentManagementModal
        show={attachmentModal.show}
        onHide={closeAttachmentModal}
        editAttachment={attachmentModal.editItem as Attachment}
        onSubmit={handleSubmit}
        isManager={isManager}
      />

      <ConfirmationModal
        show={
          deleteModal.show && deleteModal.itemToDelete?.type === 'attachment'
        }
        onHide={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        message={t(
          'attachment.confirmDelete',
          'Are you sure you want to delete this attachment?',
        )}
      />
    </div>
  )
}

export default AttachmentManagement
