import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormSection } from './FormSection'
import { InteractionSummaryForm } from '../../../types/forms'
import { CategoryOption } from '../InteractionCategory/CategoryOptions'
import { Attachment, Template } from '../../../types/domain'
import useSummaryStore from './store'
import { useSummarizeApi } from '../../../hooks/useSummarize/useSummarize'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useToast from '../../../hooks/useToast'
import MarkdownEditor from '../../core/MarkdownEditor/MarkdownEditor'

export const ManualConsultationContainer: React.FC = () => {
  const { t } = useTranslation()
  const showToast = useToast((state) => state.showToast)
  const description = useSummaryStore((state) => state.description)
  const category = useSummaryStore((state) => state.category)
  const subCategory = useSummaryStore((state) => state.subCategory)
  const setTitle = useSummaryStore((state) => state.setTitle)
  const setDescription = useSummaryStore((state) => state.setDescription)
  const setAttachments = useSummaryStore((state) => state.setAttachments)
  const setCategory = useSummaryStore((state) => state.setCategory)
  const setSubCategory = useSummaryStore((state) => state.setSubCategory)
  const summary = useSummaryStore((state) => state.summary)
  const setSummary = useSummaryStore((state) => state.setSummary)
  const summaryTitle = useSummaryStore((state) => state.summaryTitle)
  const setSummaryTitle = useSummaryStore((state) => state.setSummaryTitle)
  const summaryLoading = useSummaryStore((state) => state.summaryLoading)
  const setSummaryLoading = useSummaryStore((state) => state.setSummaryLoading)
  const summarize = useSummarizeApi()

  const form = useForm<InteractionSummaryForm>({
    mode: 'all',
    defaultValues: {
      title: '',
      description: '',
      category: null,
      subCategory: null,
      attachments: [],
      useSimplification: false,
    },
  })
  const { getValues, setValue, watch } = form
  useEffect(() => {
    const { unsubscribe } = watch((value, { name }) => {
      switch (name) {
        case 'title':
          setTitle(value.title ?? '')
          break
        case 'description':
          setDescription(value.description ?? '')
          break
        case 'attachments':
          setAttachments([...getValues('attachments')])
          break
        case 'category':
          setCategory((value.category as CategoryOption) ?? null)
          break
        case 'subCategory':
          setSubCategory((value.subCategory as CategoryOption) ?? null)
          break
        default:
          break
      }
    })
    return () => unsubscribe()
  }, [watch])

  useEffect(() => {
    return () => {
      setSubCategory(null)
      setCategory(null)
      setAttachments([])
      setTitle('')
      setDescription('')
      setSummary('')
      setSummaryTitle('')
    }
  }, [])

  useEffect(() => {
    form.setValue('description', description)
  }, [description])

  const handleRemoveAttachment = (index: number) => {
    const currentAttachments = getValues('attachments')
    setValue(
      'attachments',
      currentAttachments?.filter?.((_, i) => i !== index) || [],
      { shouldDirty: true },
    )
  }

  const handleCategoryChange = (category: CategoryOption | null) => {
    if (!category) {
      setValue('subCategory', null)
    }
    setValue('category', category)
  }

  const handleSubCategoryChange = (category: CategoryOption | null) => {
    setValue('subCategory', category)
  }

  const handleAddAttachment = (attachment: Attachment) => {
    const currentAttachments = getValues('attachments')
    if (!currentAttachments.some((att) => att.url === attachment.url)) {
      setValue('attachments', [...currentAttachments, attachment], {
        shouldDirty: true,
        shouldValidate: false,
      })
    }
  }

  const onTemplateApplied = useCallback(
    (template: Template) => {
      if (template?.attachments?.length) {
        const attachments = [...getValues('attachments')]
        template.attachments.forEach((a) => addAttachment(attachments, a))
        setValue('attachments', Array.from(new Set(attachments)), {
          shouldDirty: true,
        })
      }
    },
    [getValues, setValue],
  )

  const onSimplifyClicked = useCallback(async () => {
    try {
      setSummaryLoading(true)
      const result = await summarize.mutateAsync({
        medical_summary: description,
        language: 'NL',
        complexity: 'accessible',
      })
      setSummary(result.result)
      setSummaryTitle(result.title)
    } catch (error) {
      console.error('Error whlie requesting AI simplification: ', error)
      const errorMessage = t(
        'consult.manual.simplify.error',
        'Operation failed. please try again.',
      )
      showToast('danger', errorMessage)
      setSummary('')
      setSummaryTitle('')
    } finally {
      setSummaryLoading(false)
    }
  }, [description])

  return (
    <div
      className='manual-consult__content flex-row align-items-start justify-content-center'
    >
      <FormSection
        methods={form}
        selectedCategory={category}
        selectedSubCategory={subCategory}
        onCategoryChange={handleCategoryChange}
        onSubCategoryChange={handleSubCategoryChange}
        onAddAttachment={handleAddAttachment}
        onRemoveAttachment={handleRemoveAttachment}
        onTemplateApplied={onTemplateApplied}
        onSimplifyClicked={onSimplifyClicked}
      />
      <div className="max-w-600px w-100">
        {summaryLoading && <Spinner />}
        {summaryTitle && 
        <div className="manual-consult__simplified-title mt-200">
          {!summaryLoading && summaryTitle}
        </div>
        }
        <div className={summaryTitle && "mt-400"}>
          {!summaryLoading && (
            <div>
              <label className='form-label'>{t('consult.manual.form.simplified-summary.label', 'Simplified summary')}</label>
            <MarkdownEditor value={summary} onChange={(changes) => {
              setSummary(changes.markdown)
            }} placeholder={t('consult.manual.form.simplified-summary.placeholder','Provide summary')}></MarkdownEditor>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const addAttachment = (
  attachments: Array<Attachment>,
  attachment: Attachment,
) => {
  if (attachments.map((a) => a.url).includes(attachment.url)) {
    return
  }
  attachments.push(attachment)
}
