import { WithClassName } from '../../Icon'

export const DoctorCross = ({ className }: WithClassName) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V8L20 8C21.1046 8 22 8.89543 22 10V14C22 15.1046 21.1046 16 20 16H16V20C16 21.1046 15.1046 22 14 22H10C8.89543 22 8 21.1046 8 20L8 16L4 16C2.89543 16 2 15.1046 2 14V10C2 8.89543 2.89543 8 4 8L8 8L8 4ZM14 4L10 4L10 9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10L4 10V14L9 14C9.55229 14 10 14.4477 10 15V20H14V15C14 14.4477 14.4477 14 15 14H20V10L15 10C14.4477 10 14 9.55228 14 9V4Z"
      fill="#212529"
    />
  </svg>
)
