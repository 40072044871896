import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../../../../hooks/useAuth'
import AccountDeletion from './AccountDeletion'
import LanguageSettings from './LanguageSettings'
import LoginInformation from './LoginInformation'
import PersonalDetails from './PersonalDetails'
import PrivacyAndSecurity from './PrivacyAndSecurity'

const ProfileSettings = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  if (!user) return null

  const profile = user.profile ?? { id: uuidv4() }

  return (
    <div className="settings-content">
      <div className="settings-title">
        {t('settings.profile.title', 'Account')}
      </div>

      <PersonalDetails profile={profile} email={user.email} />
      <LoginInformation />
      <LanguageSettings />
      <PrivacyAndSecurity />
      <AccountDeletion />
    </div>
  )
}

export default ProfileSettings
