import { t } from 'i18next'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Select } from '../../core/Select/Select'
import MarkdownEditor from '../../core/MarkdownEditor/MarkdownEditor'
import {
  CategoryOption,
  useSubcategoryOptions,
  useCategoryOptions,
} from '../InteractionCategory/CategoryOptions'
import { InteractionSummaryForm } from '../../../types/forms'
import { Attachment, Template } from '../../../types/domain'
import AttachmentList from './AttachmentList'
import useLibraryStore from '../ContentLibrary/store'
import Button from '../../core/Button/Button'
import useSummaryStore from './store'

interface FormSectionProps {
  methods: UseFormReturn<InteractionSummaryForm>
  selectedCategory: CategoryOption | null
  selectedSubCategory: CategoryOption | null
  onCategoryChange: (category: CategoryOption | null) => void
  onSubCategoryChange: (category: CategoryOption | null) => void
  onAddAttachment: (attachment: Attachment) => void
  onRemoveAttachment: (index: number) => void
  onTemplateApplied: (template: Template) => void
  onSimplifyClicked: () => void
}

export const FormSection: React.FC<FormSectionProps> = ({
  methods,
  selectedCategory,
  onCategoryChange,
  onSubCategoryChange,
  onAddAttachment,
  onRemoveAttachment,
  onTemplateApplied,
  onSimplifyClicked
}) => {
  const {
    control,
    formState: { errors },
  } = methods
  const setDescriptionNonEmpty = useSummaryStore((state) => state.setDescriptionNonEmpty)
  const descriptionNonEmpty = useSummaryStore((state) => state.descriptionNonEmpty)
  const categoryOptions = useCategoryOptions()
  const subcategoryOptions = useSubcategoryOptions(
    selectedCategory ? [selectedCategory] : null,
  )

  const { featureFlags } = useLibraryStore()

  return (
    <>
      <FormProvider {...methods}>
        <Form noValidate className="w-100 max-w-600px">
          <Form.Group>
            <Form.Label>
              {t('consult.manual.form.title.label', 'Title')}
              <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Form.Control
                    {...field}
                    className="input-lg"
                    type="text"
                    isInvalid={!!errors.title}
                    placeholder={t(
                      'consult.manual.form.title.placeholder',
                      'Enter title',
                    )}
                  />
                </>
              )}
            />
          </Form.Group>

          {featureFlags.isCategoryEnabled && (
            <>
              <Form.Group>
                <Form.Label className="mt-400">
                  {t('consult.manual.form.category.label', 'Category')}
                </Form.Label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      options={categoryOptions}
                      onChange={onCategoryChange}
                    />
                  )}
                />
              </Form.Group>

              {selectedCategory && (
                <Form.Group>
                  <Form.Label className="mt-400">
                    {t('consult.manual.form.subcategory.label', 'Subcategory')}
                  </Form.Label>
                  <Controller
                    name="subCategory"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        options={subcategoryOptions}
                        onChange={onSubCategoryChange}
                      />
                    )}
                  />
                </Form.Group>
              )}
            </>
          )}

          <Form.Group>
            <Form.Label className="mt-400">
              {t('consult.manual.form.description.label', 'Description')}
              <span className="text-danger ms-1">*</span>
            </Form.Label>
            <Controller
              name="description"
              control={control}
              rules={{
                required: t(
                  'consult.manual.form.description.required',
                  'Description is required',
                ),
              }}
              render={({ field }) => (
                <>
                  <MarkdownEditor
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value.markdown)
                      setDescriptionNonEmpty(value.hasNonWhitespaceText ?? false)
                    }}
                    placeholder={t(
                      'consult.manual.form.description.placeholder',
                      'Enter description',
                    )}
                    onTemplateApplied={onTemplateApplied}
                    additionalStyles={{ editor: 'mh-300px' }}
                  />
                </>
              )}
            />
          </Form.Group>

          <Form.Group className="mt-400">
            <Form.Label>
              {t(
                'consult.manual.form.simplification.label',
                'AI simplification',
              )}
            </Form.Label>
                <div className="d-flex justify-content-between align-items-center">
                  <Button type='button' className="btn-primary" size="sm" disabled={!descriptionNonEmpty} onClick={onSimplifyClicked}>
                    {t(
                      'consult.manual.form.simplification.simplify',
                      'Simplify',
                    )}
                  </Button>
                </div>
          </Form.Group>
          <Form.Group className="manual-consult__attachments-section mt-400">
            <Controller
              name="attachments"
              control={control}
              render={({ field }) => (
                <AttachmentList
                  attachments={field.value}
                  onRemove={onRemoveAttachment}
                  onAddAttachment={(attachment) => {
                    onAddAttachment(attachment)
                  }}
                  onAttachmentDragStart={(e, attachment) => {
                    e.dataTransfer.setData(
                      'attachment',
                      JSON.stringify(attachment),
                    )
                  }}
                />
              )}
            />
          </Form.Group>
        </Form>
      </FormProvider>
    </>
  )
}
