import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { Attachment } from '../../../../types/domain'
import {
  getNormalizedUrl,
  validateAttachmentUrl,
} from '../../../../utils/urlUtils'
import Button from '../../../core/Button/Button'
import Modal from '../../../shared/Modal/Modal'
import styles from '../../InteractionSummary/Modal.module.scss'
import useLibraryStore from '../store'
import { ValidationError } from '../types'

interface AttachmentManagementModalProps {
  show: boolean
  onHide: () => void
  editAttachment: Attachment | null
  onSubmit: (data: Partial<Attachment>) => Promise<void | ValidationError>
  isManager: boolean
}

const AttachmentManagementModal: React.FC<AttachmentManagementModalProps> = ({
  show,
  onHide,
  editAttachment,
  onSubmit,
  isManager,
}) => {
  const { t } = useTranslation()
  const { isSaving, setIsSaving } = useLibraryStore()
  const [formData, setFormData] = useState<Partial<Attachment>>({})
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isNew, setIsNew] = useState(true)

  const setIsUrlValid = (isValid: boolean) => {
    if (!isValid) {
      setErrors({ ...errors, url: 'invalid' })
      return
    }
    const { url, ...newErrors } = errors
    setErrors(newErrors)
  }
  const isUrlValid = !errors.url

  useEffect(() => {
    if (show) {
      setFormData(
        editAttachment
          ? {
              id: editAttachment.id,
              displayName: editAttachment.displayName,
              description: editAttachment.description,
              url: editAttachment.url,
            }
          : {
              id: uuidv4(),
              displayName: '',
              description: '',
              url: '',
            },
      )
      setIsNew(!editAttachment)
      setIsUrlValid(true)
      setErrors({})
    }
  }, [show, editAttachment])

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value
    setFormData((prev) => ({ ...prev, url: newUrl }))
    if (newUrl) {
      setIsUrlValid(validateAttachmentUrl(newUrl))
    } else {
      setIsUrlValid(true)
    }
  }

  const isFormValid = useMemo(() => {
    return !!(
      formData.displayName?.trim() &&
      formData.url?.trim() &&
      isUrlValid &&
      !errors.display_name
    )
  }, [
    formData.displayName,
    formData.description,
    formData.url,
    isUrlValid,
    errors,
  ])

  const handleClose = () => {
    onHide()
    setFormData({})
    setErrors({})
    setIsUrlValid(true)
    setIsNew(true)
  }

  const handleSubmit = async () => {
    if (!isFormValid || isSaving) return

    try {
      setIsSaving(true)
      const validationError = await onSubmit({
        ...formData,
        url: getNormalizedUrl(formData.url ?? ''),
      })
      if (validationError) {
        setErrors(validationError.details)
      }
    } catch (error) {
      console.error('Error saving attachment:', error)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>
          {editAttachment
            ? t('library.attachment.modal.edit', 'Edit Attachment')
            : t('library.attachment.modal.add', 'Add Attachment')}
        </h3>
      </div>
      <div className={styles.bodyContainer}>
        <Form.Group>
          <Form.Label>
            {t('library.attachment.modal.name.label', 'Name')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="input-lg"
            value={formData.displayName || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setErrors({})
              setFormData({ ...formData, displayName: e.target.value })
            }}
            placeholder={t(
              'library.attachment.modal.name.placeholder',
              'Attachment Name',
            )}
            required
            isInvalid={!!errors.display_name}
          />
          <Form.Control.Feedback type="invalid">
            {!!errors.display_name &&
              t(
                'library.attachment.modal.name.not-unique',
                'Please enter unique name',
              )}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mt-400">
          <Form.Label>
            {t('library.attachment.modal.description.label', 'Description')}
          </Form.Label>
          <Form.Control
            as="textarea"
            className="input-lg"
            value={formData.description || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setFormData({ ...formData, description: e.target.value })
            }
            placeholder={t(
              'library.attachment.modal.description.placeholder',
              'Description',
            )}
          />
        </Form.Group>

        <Form.Group className="mt-400">
          <Form.Label>
            {t('library.attachment.modal.url.label', 'URL')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="url"
            className="input-lg"
            value={formData.url || ''}
            onChange={handleUrlChange}
            placeholder={t(
              'library.attachment.modal.url.placeholder',
              'Attachment URL',
            )}
            isInvalid={!isUrlValid}
            required
          />
          <Form.Control.Feedback type="invalid">
            {t(
              'library.attachment.modal.url.invalid',
              'Please enter a valid URL',
            )}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
        <Button variant="link" onClick={handleClose} className={styles.button}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button
          className="btn-primary"
          size="sm"
          disabled={!isFormValid || isSaving}
          onClick={handleSubmit}
        >
          {isNew
            ? t('common.save.new', 'Save')
            : t('common.save.changes', 'Save')}
        </Button>
      </div>
    </Modal>
  )
}

export default AttachmentManagementModal
