import React, { useEffect, useState } from 'react'
import { useSendSoundToApi } from '../../../../hooks/Ai/useSendSoundToApi/useSendSoundToApi'
import { t } from 'i18next'
import Button from '../../../core/Button/Button'
import useStore, { Page } from '../store'
import AudioVisualizerSpeed from './AudioVisualizerSpeed'
import styles from './RecordingInProgress.module.scss'
import { Cancelled } from '../../../../hooks/Ai/useSendSoundToApi/types'
import Modal from '../../../shared/Modal/Modal'
import { BlockerFunction, useBlocker } from 'react-router-dom'
import { LeaveRecordingDialog } from './NavigationBlockerModal/LeaveRecordingDialog'
import { useAiMutationDelete } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'

const RecordingInProgress: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean | Cancelled>(true)
  const setPage = useStore((state) => state.setPage)
  const consultId = useStore((state) => state.consultId)
  const setConsultId = useStore((state) => state.setConsultId)

  const deleteMutation = useAiMutationDelete({
    path: `/consultation/${consultId}`,
  })

  useSendSoundToApi(isRecording, () => {
    if (isRecording === (null as Cancelled)) {
      setConsultId(-1)
      setPage(Page.newAi)
    } else {
      setPage(Page.aiRecordingSummary)
    }
  })
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      // Chrome requires setting returnValue to show a confirmation dialog
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      Boolean(isRecording) &&
      currentLocation.pathname !== nextLocation.pathname,
    [isRecording],
  )
  let blocker = useBlocker(shouldBlock)

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          padding: '16px',
          background: 'white',
          borderRadius: '29px',
        }}
      >
        <AudioVisualizerSpeed isRecording={Boolean(isRecording)} />
        <p
          style={{
            fontSize: '14px',
            fontWeight: '400px',
            color: '#000',
            opacity: '0.5',
            paddingBottom: '16px',
          }}
        >
          {t(
            'consult.visualizer.recording-in-process',
            'Recording in process...',
          )}
        </p>
        <Button
          disabled={!isRecording}
          onClick={() => {
            setIsRecording(false)
          }}
        >
          {isRecording
            ? t('consult.visualizer.stop-recording', 'Stop recording')
            : t(
                'consult.visualizer.stopping-recording',
                'Stopping recording...',
              )}
        </Button>
        {isRecording && (
          <Button
            className={styles.cancelBtn}
            variant="link"
            onClick={() => {
              setIsRecording(null as Cancelled)
            }}
          >
            {t('consult.visualizer.cancel', 'Cancel')}
          </Button>
        )}
      </div>

      <Modal
        show={blocker.state === 'blocked'}
        dialogClassName={`${styles.dialogContainer}`}
        contentClassName="rounded-2"
      >
        <LeaveRecordingDialog
          onLeave={async () => {
            try {
              setIsRecording(null as Cancelled)
              await deleteMutation.mutateAsync(null)
            } finally {
              blocker.proceed?.()
            }
          }}
          onStay={() => blocker.reset?.()}
        />
      </Modal>
    </>
  )
}

export default RecordingInProgress
