import React, { useEffect, useMemo } from 'react'
import styles from './SoapReport.module.scss'
import { SoapSection } from './SoapSection'
import useStore from '../store'
import { t } from 'i18next'
import AttachmentList from '../../InteractionSummary/AttachmentList'
import { Attachment } from '../../../../types/domain'
import { PATIENT_PROTOCOL } from '../../../../utils/constants'

export const SoapReport: React.FC<{
  type: string
}> = ({ type }) => {
  const editedSummaries = useStore((state) => state.editedSummaries)
  const consultId = useStore((state) => state.consultId)
  const sharedAttachments = useStore((state) => state.sharedAttachments)
  const setSharedTemplates = useStore((state) => state.setSharedAttachments)
  useEffect(() => {
    return () => {
      setSharedTemplates([])
    }
  }, [consultId])
  const sections = useMemo(
    () =>
      editedSummaries
        .find((s) => s.protocol_name === type)
        ?.content.map((c) => {
          const titleMap = {
            S: t('consult.report.title.subjective', 'Subjective'),
            O: t('consult.report.title.objective', 'Objective'),
            A: t('consult.report.title.assessment', 'Assessment'),
            P: t('consult.report.title.plan', 'Plan'),
            SUMMARY: t('consult.report.title.summary', 'PATIENT REPORT'),
          }
          return {
            field_name: c.field_name,
            title: titleMap[c.field_name as keyof typeof titleMap],
            content: c.content,
          }
        }) || [],
    [editedSummaries, type],
  )

  const onRemoveAttachment = (index: number) => {
    setSharedTemplates(sharedAttachments.filter?.((_, i) => i !== index))
  }

  const handleAddAttachment = (attachment: Attachment) => {
    if (
      !sharedAttachments.some((att: Attachment) => att.url === attachment.url)
    ) {
      setSharedTemplates([...sharedAttachments, attachment])
    }
  }

  return (
    <div className={styles.reportContent}>
      {sections.map((section) => (
        <SoapSection
          key={section.title}
          title={section.title}
          content={section.content}
          fieldName={section.field_name}
          protocol={type}
        />
      ))}
      {type === PATIENT_PROTOCOL && (
        <AttachmentList
          attachments={sharedAttachments}
          onRemove={onRemoveAttachment}
          onAddAttachment={(attachment) => {
            handleAddAttachment(attachment)
          }}
          onAttachmentDragStart={(e, attachment) => {
            e.dataTransfer.setData('attachment', JSON.stringify(attachment))
          }}
        />
      )}
    </div>
  )
}
