import React from 'react'
import { Link } from 'react-router-dom'
import { Paths } from '../../utils/paths'

const BrandLogo: React.FC<{ width?: number; height?: number }> = ({
  width = 30,
  height = 30,
}) => {
  return (
    <Link to={`/${Paths.consultation}`} className="d-flex">
      <img
        width={width}
        height={height}
        src="/media/logo.svg"
        alt="static-content.logo.display-name"
      />
    </Link>
  )
}

export default BrandLogo
