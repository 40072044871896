import { UserMenu } from '../../features/UserMenu/UserMenu'
import BrandLogo from '../BrandLogo'
import { ActionButtons } from '../../features/Consult/ActionButtons/ActionButtons'

interface HeaderProps {
  hideMenu?: boolean
}
export function Header({ hideMenu }: HeaderProps) {
  return (
    <>
      <DesktopHeader hideMenu={hideMenu} />
    </>
  )
}

const DesktopHeader = ({ hideMenu }: HeaderProps) => {
  return (
    <header className="app-header d-lg-flex">
      <h1 className="app-header__title">
        <BrandLogo width={35} height={35} />
      </h1>
      <div className="app-header__nav | ms-auto">
        <ActionButtons />
        <UserMenu />
      </div>
    </header>
  )
}
