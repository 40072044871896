import { create } from 'zustand'
import { UserProfile } from '../../../types/domain'

type SettingsPage = 'profile' | 'privacy' | 'support' | 'dittoai' | 'vocabulary'
type SupportSubPage = 'main' | 'faq'

interface ProfileModal {
  show: boolean
  editProfile: UserProfile | null
}

interface SettingsState {
  page: SettingsPage
  supportSubPage: SupportSubPage
  profileModal: ProfileModal
  setPage: (page: SettingsPage) => void
  setSupportSubPage: (subPage: SupportSubPage) => void
  openProfileModal: (profile: UserProfile) => void
  closeProfileModal: () => void
  isSaving: boolean
  setIsSaving: (saving: boolean) => void
}

const useSettingsStore = create<SettingsState>((set) => ({
  page: 'profile',
  supportSubPage: 'main',
  profileModal: {
    show: false,
    editProfile: null,
  },
  isSaving: false,
  setPage: (page) => set({ page }),
  setSupportSubPage: (subPage) => set({ supportSubPage: subPage }),
  openProfileModal: (profile: UserProfile) => 
    set({ profileModal: { show: true, editProfile: profile } }),
  closeProfileModal: () => 
    set({ profileModal: { show: false, editProfile: null } }),
  setIsSaving: (saving: boolean) => set({ isSaving: saving }),
}))

export default useSettingsStore 