import { create } from 'zustand'
import { CategoryOption } from '../InteractionCategory/CategoryOptions'
import { Attachment } from '../../../types/domain'

interface SummaryState {
  title: string
  description: string
  descriptionNonEmpty: boolean
  category: CategoryOption | null
  subCategory: CategoryOption | null
  attachments: Attachment[]
  summary: string
  summaryTitle: string
  summaryLoading: boolean

  setTitle: (title: string) => void
  setDescription: (description: string) => void
  setCategory: (category: CategoryOption|null) => void
  setSubCategory: (subCategory: CategoryOption|null) => void
  setAttachments: (attachments: Attachment[]) => void
  setSummary: (summary: string) => void
  setSummaryTitle: (summaryTitle: string) => void
  setSummaryLoading: (summaryLoading: boolean) => void
  setDescriptionNonEmpty: (descriptionNonEmpty: boolean) => void
}

const useSummaryStore = create<SummaryState>((set) => ({
  title: '',
  description: '',
  descriptionNonEmpty: false,
  category: null,
  subCategory: null,
  attachments: [],
  useSimplification: false,
  summary: '',
  summaryTitle: '',
  summaryLoading: false,

  setTitle: (title: string) => set(() => ({ title })),
  setDescription: (description: string) => set(() => ({ description })),
  setCategory: (category: CategoryOption|null) => set(() => ({ category })),
  setSubCategory: (subCategory: CategoryOption|null) => set(() => ({ subCategory })),
  setAttachments: (attachments: Attachment[]) => set(() => ({ attachments })),
  setSummary: (summary: string) => set(() => ({summary})),
  setSummaryTitle: (summaryTitle: string) => set(() => ({summaryTitle})),
  setSummaryLoading: (summaryLoading: boolean) => set(() => ({summaryLoading})),
  setDescriptionNonEmpty: (descriptionNonEmpty: boolean) => set(() => ({descriptionNonEmpty}))
}))

export default useSummaryStore
