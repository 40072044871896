import { useTranslation } from 'react-i18next'
import { ICON_TYPE } from '../../../core/Icon/Icon'
import useSettingsStore from '../store'
import FaqContainer from '../Faq/FaqContainer'

const SupportSettings = () => {
  const { t } = useTranslation()
  const { supportSubPage, setSupportSubPage } = useSettingsStore()

  if (supportSubPage === 'faq') {
    return <FaqContainer />
  }

  const supportItems = [
    {
      id: 'btn_support_faqs',
      title: t('support.faq', 'FAQs'),
      subtitle: t(
        'support.faq.details',
        'If you have any questions, see if they are answered here.',
      ),
      icon: ICON_TYPE.FAQ,
      onClick: () => setSupportSubPage('faq'),
    },
  ]

  return (
    <div className="settings-content">
      <div className="settings-title">{t('support.title', 'Support')}</div>
      <div className="settings-subtitle regular-300-gray-700">
        {t('support.subtitle', 'Access help resources and contact support.')}
      </div>

      {supportItems.map((item) => (
        <div key={item.id} className="settings-row cursor-pointer" onClick={item.onClick}>
          <div className="description">
            <div className="title">
              {item.title}
            </div>
            <div className="subtext">{item.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SupportSettings
