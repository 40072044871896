import React from 'react'
import styles from './LeaveRecordingDialog.module.scss'
import { Button } from './Button'

interface LeaveRecordingDialogProps {
  onStay: () => void
  onLeave: () => void
}

export const LeaveRecordingDialog: React.FC<LeaveRecordingDialogProps> = ({
  onStay,
  onLeave,
}) => {
  return (
    <div className={styles.dialog} role="dialog" aria-labelledby="dialog-title">
      <h2 id="dialog-title" className={styles.title}>
        Leave recording
      </h2>
      <p className={styles.message}>
        By leaving this page you will cancel and delete current recording
        session.
        <br />
        Are you sure?
      </p>
      <div className={styles.buttonContainer}>
        <Button variant="secondary" onClick={onStay}>
          Stay
        </Button>
        <Button variant="primary" onClick={onLeave}>
          Leave
        </Button>
      </div>
    </div>
  )
}
