import React, { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore, { Page } from './features/Consult/store'
import useSummaryStore from './features/InteractionSummary/store'
import { useSummarizeApi } from '../hooks/useSummarize/useSummarize'
import { useTranslation } from 'react-i18next'
import useToast from '../hooks/useToast'

const ElectronListener: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setPage = useStore((state) => state.setPage)
  const setDescription = useSummaryStore((state) => state.setDescription)
  const setDescriptionNonEmpty = useSummaryStore((state) => state.setDescriptionNonEmpty)
  const summarize = useSummarizeApi()
  const setSummary = useSummaryStore((state) => state.setSummary)
  const setSummaryTitle = useSummaryStore((state) => state.setSummaryTitle)
  const setSummaryLoading = useSummaryStore((state) => state.setSummaryLoading)
  const showToast = useToast((state) => state.showToast)

  useEffect(() => {
    const ipc = window.electron!
    ipc.onOcrConsultationRequested(async (data) => {
      await navigate('/consultation')
      setTimeout(async () => {
        setPage(Page.newManual)
        const descriptionText = data.join('\n')
        setDescription(descriptionText)
        setDescriptionNonEmpty(!!descriptionText.trim())
        try {
          setSummaryLoading(true)
          const result = await summarize.mutateAsync({ medical_summary: descriptionText, language: 'NL', complexity:'accessible' })
          setSummary(result.result)
          setSummaryTitle(result.title)
        } catch (error) {
          console.error('Error whlie requesting AI simplification: ', error)
          const errorMessage = t(
            'consult.manual.simplify.error',
            'Operation failed. please try again.',
          )
          showToast('danger', errorMessage)
          setSummary('')
          setSummaryTitle('')
        } finally {
          setSummaryLoading(false)
        }
      }, 500)
    })
    return () => {
      ipc.ipcRenderer.unhook('ocr-consultation-requested');
    }
  }, [])
  return <></>
}

const ElectronChecker: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const isRunningInElectron = !!window.electron
  return (
    <>
      {isRunningInElectron && <ElectronListener />}
      {children}
    </>
  )
}

export default ElectronChecker
