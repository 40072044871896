import React from 'react'
import QRModal from '../features/InteractionSummary/QRCodeModal'
import QRModalStreaming from '../features/InteractionSummary/QRCodeModalStreaming'

const QrModalShared: React.FC<{
  close: () => void
  show: boolean
  data: string | null
  dataStream: string[] | null
}> = ({ data, dataStream, show, close }) => {
  return (
    <>
      {data && <QRModal show={show} onHide={close} qrData={data} />}
      {dataStream && (
        <QRModalStreaming show={show} onHide={close} qrData={dataStream} />
      )}
    </>
  )
}

export default QrModalShared
