import React from 'react'
import { useAttachments } from '../../../hooks/useAttachment/useAttachmentBase'
import { Spinner } from 'react-bootstrap'
import {
  Attachment,
  LibraryItemType,
  TYPE_TRANSLATIONS,
} from '../../../types/domain'
import { SearchInput } from '../../core/SearchInput/SearchInput'
import { LibraryCard } from '../../core/LibraryCard/LibraryCard'
import { AttachmentPreview } from '../../core/AttachmentPreview/AttachmentPreview'
import { useTranslation } from 'react-i18next'

export const AttachmentLibrary: React.FC = () => {
  const { t } = useTranslation()
  const { data: attachments = [], isLoading } = useAttachments()
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleAttachmentDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    attachment: Attachment,
  ) => {
    e.dataTransfer.setData('attachment', JSON.stringify(attachment))
  }

  const getContentByType = (items: Attachment[], type: LibraryItemType) => {
    return items.filter(
      (attachment) =>
        attachment.displayName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) && attachment.type === type,
    )
  }

  const renderSection = (type: LibraryItemType) => {
    const attachmentsForType = getContentByType(attachments, type)

    return (
      <div className="library-list__section" key={type}>
        <div className="library-list__section-title">
          {t(TYPE_TRANSLATIONS[type as keyof typeof TYPE_TRANSLATIONS])}
        </div>
        <div className="library-list__section-list-wrapper">
          {attachmentsForType.length > 0 ? (
            attachmentsForType.map((attachment) => (
              <div
                className="library-list__section-item-wrapper"
                key={attachment.id}
                draggable
                onDragStart={(e) => handleAttachmentDragStart(e, attachment)}
              >
                <div className="library-list__section-item">
                  <LibraryCard
                    isAttachment={true}
                    title={attachment.url}
                    size="small"
                    content={
                      <AttachmentPreview
                        size="small"
                        metadata={
                          attachment.metadata || {
                            title: '',
                            description: '',
                            domain: '',
                          }
                        }
                      />
                    }
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="library-list__empty-message">
              {t('library.type.empty-group', 'No elements available')}
            </div>
          )}
        </div>
      </div>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <div className="library-list">
      <div className="library-list-wrapper">
        <div className="library-list__search-wrapper">
          <SearchInput onChange={setSearchTerm} value={searchTerm} />
        </div>
        {renderSection('Personal')}
        {renderSection('Organization')}
        {renderSection('General')}
      </div>
    </div>
  )
}
