import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { Template } from '../../../../types/domain'
import { AttachmentCounter } from '../../../core/AttachmentCounter/AttachmentCounter'
import { LibraryCard } from '../../../core/LibraryCard/LibraryCard'

interface TemplateCardProps {
  template: Template
  onEdit: (template: Template) => void
  onDelete: (id: string | undefined) => void
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  onEdit,
  onDelete,
}) => {
  const { i18n } = useTranslation()

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString(i18n.language, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
  }

  const TemplateContent = () => (
    <div className="template-card__content-wrapper">
      <Markdown>{template.content}</Markdown>
    </div>
  )

  return (
    <LibraryCard
      title={template.displayName}
      date={template.updatedAt ? formatDate(template.updatedAt) : undefined}
      content={<TemplateContent />}
      titleAdornment={<AttachmentCounter count={template.attachmentCount} />}
      onEdit={() => onEdit(template)}
      onDelete={() => template.id && onDelete(template.id)}
    />
  )
}
