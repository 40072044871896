import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const PrivacyAndSecurity: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="settings-section-title">
        {t('settings.profile.privacy.and.security', 'Privacy and Security')}
      </div>
      <div className="settings-row flex-column justify-content-start align-items-start">
        <Link to="/terms" className="link" target="_blank">
          {t('common.terms&conditions', 'Terms & conditions')}
        </Link>
        <Link to="/privacy-policy" className="link" target="_blank">
          {t('common.privacy.policy', 'Privacy policy')}
        </Link>
        <Link to="/cookie-policy" className="link" target="_blank">
          {t('common.cookie.policy', 'Cookie policy')}
        </Link>
      </div>
    </>
  )
}

export default PrivacyAndSecurity
