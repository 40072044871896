import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import Button from '../../core/Button/Button'
import { Attachment } from '../../../types/domain'
import AddLinkModal from './AddLinkModal'
import Icon, { ICON_TYPE } from '../../core/Icon/Icon'
import { AttachmentPreview } from '../../core/AttachmentPreview/AttachmentPreview'
import { LibraryCard } from '../../core/LibraryCard/LibraryCard'
import {
  dragEventHandler,
  useDragAndDropStore,
} from '../../../hooks/useDragAndDrop/useDragAndDrop'
import { Form } from 'react-bootstrap'

interface AttachmentListProps {
  attachments: Attachment[]
  onRemove: (index: number) => void
  onAddAttachment: (attachment: Attachment) => void
  onAttachmentDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    attachment: Attachment,
  ) => void
}

const AttachmentList: React.FC<AttachmentListProps> = ({
  attachments,
  onRemove,
  onAddAttachment,
  onAttachmentDragStart,
}) => {
  const detachDropHandler = useDragAndDropStore(
    (state) => state.detachDropHandler,
  )
  const attachDropHandler = useDragAndDropStore(
    (state) => state.attachDropHandler,
  )
  const [showAddModal, setShowAddModal] = useState(false)
  const [isDropHandlerAttached, setIsDropHandlerAttached] = useState(false)

  const handleAddAttachment = (url: string) => {
    const newAttachment = {
      displayName: url,
      url: url,
    } as Attachment
    onAddAttachment(newAttachment)
  }

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowAddModal(true)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const attachmentData = e.dataTransfer.getData('attachment')
    if (attachmentData) {
      const attachment: Attachment = JSON.parse(attachmentData)
      onAddAttachment(attachment)
    }
  }

  useEffect(() => {
    if (isDropHandlerAttached) {
      return
    }
    setIsDropHandlerAttached(true)
    attachDropHandler(handleDrop as dragEventHandler)
    return () => {
      detachDropHandler(handleDrop as dragEventHandler)
      setIsDropHandlerAttached(false)
    }
  }, [])

  return (
    <div className="w-100 d-flex flex-column justify-content-start align-items-start">
      <div className="manual-consult__attachments-title">
        {t('consult.manual.form.attachments.label', 'Attached files')}
      </div>
      <div
        className="attachment-list w-100"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {attachments.map((attachment, index) => (
          <div
            className="w-100 d-flex align-items-center justify-content-between mb-2"
            key={index}
          >
            <LibraryCard
              title={attachment.url}
              isAttachment={true}
              content={
                <AttachmentPreview
                  metadata={
                    attachment.metadata || {
                      title: '',
                      description: '',
                      domain: '',
                    }
                  }
                />
              }
            />
            <Button
              variant="link"
              type="button"
              onClick={() => onRemove(index)}
              className="p-0 ms-2 close-btn"
            >
              <Icon icon={ICON_TYPE.CLOSE} className="text-dark" />
            </Button>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <Button
            id="add_link-btn"
            icon="plus"
            variant="secondary-outline"
            onClick={handleAddClick}
          >
            &nbsp;{t('consult.manual.add-link.add-button', 'Add link')}
          </Button>
        </div>

        <AddLinkModal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          onAdd={handleAddAttachment}
        />
      </div>
    </div>
  )
}

export default AttachmentList
