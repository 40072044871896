import React from 'react'
import Modal from '../../shared/Modal/Modal'
import QRCode from 'react-qr-code'
import Button from '../../core/Button/Button'
import styles from './Modal.module.scss'
import { useTranslation } from 'react-i18next'
import BrandLogo from '../../shared/BrandLogo'

interface QRModalProps {
  show: boolean
  onHide: () => void
  qrData: string
}

const QRModal: React.FC<QRModalProps> = ({ show, onHide, qrData }) => {
  const { t } = useTranslation()
  return (
    <Modal
      show={show}
      dialogClassName="max-w-600px"
      contentClassName="rounded-2"
      onHide={onHide}
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>
          {t('share.title', 'Share Ditto QR code')}
        </h3>
      </div>
      <div
        className={`d-flex flex-column align-items-center ${styles.bodyContainer}`}
      >
        <h4 className={styles.subtitle}>
          {t('share.subtitle', 'Please share this QR with your patient.')}
        </h4>
        <div className={styles.qrContainer}>
          <QRCode className={styles.qr} value={qrData} level="H" size={320} />
          <div className={styles.qrLogo}>
            <BrandLogo height={70} width={70} />
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-end ${styles.footerContainer}`}>
        <Button
          variant="link"
          type="button"
          onClick={onHide}
          className={styles.button}
        >
          {t('common.cancel', 'Cancel')}
        </Button>
      </div>
    </Modal>
  )
}

export default QRModal
