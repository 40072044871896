import { Trans, useTranslation } from 'react-i18next'
import CookieSettings from './CookieSettings'
import { Link } from 'react-router-dom'

const PrivacySettings = () => {
  const { t } = useTranslation()

  return (
    <div className="settings-content">
      <div className="settings-title">
        {t('settings.privacy.title', 'Privacy Settings')}
      </div>
      <div className="settings-subtitle regular-300-gray-700">
        {t(
          'settings.privacy.subtitle',
          'Manage your privacy and cookie preferences.',
        )}
        <div>
          <Trans
            i18nKey="settings.privacy.cookies.policy"
            t={t}
            defaults="Read our <Link>Cookie Policy</Link> to learn more."
            components={{
              Link: <Link to="/cookie-policy" target="_blank" />,
            }}
          />
        </div>
      </div>

      <CookieSettings />
    </div>
  )
}

export default PrivacySettings
