import { Result, User, UserProfile } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthMutationDelete,
  useAuthMutationPost,
  useAuthQuery,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = `${getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL') || ''}/users`

export const useCurrentUser = () => {
  return useAuthQuery<Result<User>>(['currentUser'], {
    baseUrl: API_BASE_URL,
    path: '/profile',
  })
}

export const useUpsertProfile = () => {
  return useAuthMutationPost<Result<UserProfile>, Error, Partial<UserProfile>>({
    baseUrl: API_BASE_URL,
    path: `/profile`,
  })
}

export const useAllUsers = () => {
  return useAuthQuery<Result<User[]>>(['users'], {
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useDeleteAccount = () => {
  return useAuthMutationDelete<Result<void>, Error>({
    baseUrl: API_BASE_URL,
    path: '/profile',
  })
}

export const useResetPassword = () => {
  return useAuthMutationPost<Result<void>, Error>({
    baseUrl: API_BASE_URL,
    path: `/profile/reset-password`,
  })
}
