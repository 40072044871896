import Form from 'react-bootstrap/Form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../hooks/useAuth'
import { getHcpTypeOptions, UserProfile } from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import { Select } from '../../../core/Select/Select'
import styles from '../../InteractionSummary/Modal.module.scss'
import useSettingsStore from '../store'

interface Props {
  profile: UserProfile
  onSubmit: (data: Partial<UserProfile>) => Promise<void>
  onCancel: () => void
}

const EditPersonalDetails = ({ profile, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isSaving } = useSettingsStore()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: profile.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      jobTitle: profile.jobTitle,
      institution: profile.institution,
      phoneCountryCode: profile.phoneCountryCode,
      phoneNumber: profile.phoneNumber,
    },
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.bodyContainer}>
        <Form.Group>
          <Form.Label>
            {t('settings.profile.edit.email.label', 'Email')}
          </Form.Label>
          <Form.Control disabled type="text" value={user?.email} />
        </Form.Group>

        <Form.Group className="d-flex mt-400">
          <div className="w-100 me-3">
            <Form.Label>
              {t('settings.profile.edit.firstName.label', 'First Name')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="firstName"
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  className={errors.firstName ? 'is-invalid' : ''}
                  placeholder={t(
                    'settings.profile.edit.firstName.placeholder',
                    'Enter your first name',
                  )}
                />
              )}
            />
            {errors.firstName && (
              <div className="invalid-feedback">
                {t(
                  'settings.profile.edit.firstName.required',
                  'First name is required',
                )}
              </div>
            )}
          </div>
          <div className="w-100">
            <Form.Label>
              {t('settings.profile.edit.lastName.label', 'Last Name')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  className={errors.lastName ? 'is-invalid' : ''}
                  placeholder={t(
                    'settings.profile.edit.lastName.placeholder',
                    'Enter your last name',
                  )}
                />
              )}
            />
            {errors.lastName && (
              <div className="invalid-feedback">
                {t(
                  'settings.profile.edit.lastName.required',
                  'Last name is required',
                )}
              </div>
            )}
          </div>
        </Form.Group>

        <Form.Group className="mt-400">
          <Form.Label>
            {t('settings.profile.edit.jobTitle.label', 'Job Title')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="jobTitle"
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <Select
                options={getHcpTypeOptions()}
                value={getHcpTypeOptions().find(
                  (opt) => opt.value === field.value,
                )}
                onChange={(option) => field.onChange(option?.value)}
                placeholder={t(
                  'settings.profile.edit.jobTitle.placeholder',
                  'Select your job title',
                )}
                isSearchable={false}
                className={error ? 'is-invalid' : ''}
              />
            )}
          />
          {errors.jobTitle && (
            <div className="invalid-feedback">
              {t(
                'settings.profile.edit.jobTitle.required',
                'Job title is required',
              )}
            </div>
          )}
        </Form.Group>

        <Form.Group className="mt-400">
          <Form.Label>
            {t('settings.profile.edit.institution.label', 'Institution')}
          </Form.Label>
          <Controller
            control={control}
            name="institution"
            render={({ field }) => (
              <Form.Control
                {...field}
                type="text"
                placeholder={t(
                  'settings.profile.edit.institution.placeholder',
                  'Enter your institution',
                )}
              />
            )}
          />
        </Form.Group>

        <Form.Group className="d-flex mt-400">
          <div className="w-25 me-3">
            <Form.Label>
              {t(
                'settings.profile.edit.phoneCountryCode.label',
                'Country Code',
              )}
            </Form.Label>
            <Controller
              control={control}
              name="phoneCountryCode"
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder={t(
                    'settings.profile.edit.phoneCountryCode.placeholder',
                    'ex.: +31',
                  )}
                />
              )}
            />
          </div>
          <div className="w-75">
            <Form.Label>
              {t('settings.profile.edit.phoneNumber.label', 'Phone Number')}
            </Form.Label>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder={t(
                    'settings.profile.phoneNumber.placeholder',
                    'Enter your phone number',
                  )}
                />
              )}
            />
          </div>
        </Form.Group>
      </div>
      <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
        <Button variant="link" onClick={onCancel} className={styles.button}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button
          className="btn-primary"
          size="sm"
          type="submit"
          disabled={!isDirty || !isValid || isSaving}
          isLoading={isSaving}
        >
          {t('common.save', 'Save')}
        </Button>
      </div>
    </Form>
  )
}

export default EditPersonalDetails
