import { useRef, useState } from 'react'
import useStore, {
  Transcript,
} from '../../../components/features/Consult/store'
import {
  useAiMutationDelete,
  useAiMutationPost,
} from '../useAiApiQuery/useAiApiQuery'
import { useOnPropChangedOnce } from '../../useOnPropChangedOnce/useOnPropChangedOnce'
import { useTestMode } from '../../../utils/utils'
import { mockAiApiReponse } from './mock'
import { useQueryClient } from '@tanstack/react-query'
import { Cancelled } from './types'

export function useSendSoundToApi(
  isRecording: boolean | Cancelled,
  stopCallback: () => void,
) {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>()
  const consultId = useStore((state) => state.consultId)
  const deviceId = useStore((state) => state.deviceId)
  const setTranscript = useStore((state) => state.setTranscript)
  const setSummaries = useStore((state) => state.setEditedSummaries)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const queryClient = useQueryClient()

  const pushChunkMutation = useAiMutationPost({
    path: `/consultation/${consultId}/audio/chunk`,
  })
  const isTestMode = useTestMode()

  const stopChunkMutation = useAiMutationPost({
    path: `/consultation/${consultId}/finish`,
  })

  const deleteMutation = useAiMutationDelete({
    path: `/consultation/${consultId}`,
  })

  useOnPropChangedOnce(
    () => {
      console.log('API consult device', consultId, deviceId)
      if (!isTestMode) {
        startRecording()
      }
    },
    async () => {
      if (!isTestMode) {
        await stopRecording()
      } else {
        setTranscript(mockAiApiReponse)
        setSummaries(mockAiApiReponse.summaries)
      }
      stopCallback()
    },
    Boolean(isRecording),
  )

  async function startRecording() {
    let chunk_index = 0
    //
    const constraints: MediaStreamConstraints = {
      audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
    }

    navigator.mediaDevices.getUserMedia(constraints).then(async (stream) => {
      let recorder: MediaRecorder
      console.log('started')

      const options = {
        audioBitsPerSecond: 128000,
        mimeType: 'audio/webm;codecs="opus"',
      }

      recorder = new MediaRecorder(stream, options)
      setMediaRecorder(recorder)

      recorder.start(2000)

      recorder.ondataavailable = (event) => {
        console.log('Push audio')

        sendAudioToServer(event.data, chunk_index, recorder.mimeType)
        chunk_index++
      }
    })
  }
  async function sendAudioToServer(
    audioBlob: Blob,
    chunk_index: number,
    mime_type: string,
  ) {
    const formData = new FormData()
    formData.append('file', audioBlob, 'recording.webm')
    formData.append('chunk_index', String(chunk_index))
    formData.append('mime_type', mime_type)

    try {
      const requestPromise = pushChunkMutation.mutateAsync(formData)
      await requestPromise
    } catch (error) {
      console.error('Error:', error)
    }
  }

  async function stopRecording() {
    if (mediaRecorder) {
      // Stop the MediaRecorder
      mediaRecorder.stop()

      // Ensure the `onstop` event is handled
      const recorderStoppedPromise = new Promise<void>((resolve) => {
        mediaRecorder.onstop = () => {
          resolve()
        }
      })

      // Stop all media stream tracks
      mediaStreamRef.current?.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, state: ${track.readyState}`)
        track.stop()
      })
      mediaStreamRef.current = null

      // Wait for the recorder to fully stop
      await recorderStoppedPromise
    }

    try {
      if (isRecording === false) {
        const data = await stopChunkMutation.mutateAsync(null)
        await queryClient.invalidateQueries({
          queryKey: ['consultation_list'],
          exact: true,
        })
        setTranscript(data as Transcript)
        setSummaries((data as Transcript).summaries)
      } else if (isRecording === (null as Cancelled)) {
        await deleteMutation.mutateAsync(null)
      }
    } catch (e) {
      console.error('Failed to finalize recording:', e)
    }
  }
}
