import { WithClassName } from '../../Icon'

export const PatientIcon = ({ className }: WithClassName) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17C10 16.4477 10.4477 16 11 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H11C10.4477 18 10 17.5523 10 17Z"
      fill="#212529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64017 14.2318C9.06445 14.5854 9.12177 15.2159 8.76821 15.6402L6.26821 18.6402C5.94104 19.0328 5.3705 19.1156 4.94528 18.8321L3.44528 17.8321C2.98576 17.5257 2.86158 16.9049 3.16793 16.4453C3.47429 15.9858 4.09516 15.8616 4.55468 16.168L5.30702 16.6695L7.23176 14.3598C7.58533 13.9356 8.21589 13.8782 8.64017 14.2318Z"
      fill="#212529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12C10 11.4477 10.4477 11 11 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H11C10.4477 13 10 12.5523 10 12Z"
      fill="#212529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64017 9.23168C9.06445 9.58525 9.12177 10.2158 8.76821 10.6401L6.26821 13.6401C5.94105 14.0327 5.37053 14.1154 4.94531 13.832L3.44531 12.832C2.98577 12.5257 2.86158 11.9048 3.16792 11.4453C3.47425 10.9858 4.09512 10.8616 4.55466 11.1679L5.307 11.6694L7.23176 9.35972C7.58533 8.93544 8.21589 8.87812 8.64017 9.23168Z"
      fill="#212529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7C10 6.44772 10.4477 6 11 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H11C10.4477 8 10 7.55228 10 7Z"
      fill="#212529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64017 4.2318C9.06445 4.58537 9.12177 5.21593 8.76821 5.64021L6.26821 8.64021C5.94104 9.03281 5.3705 9.11555 4.94528 8.83208L3.44528 7.83208C2.98576 7.52572 2.86158 6.90485 3.16793 6.44532C3.47429 5.9858 4.09516 5.86162 4.55468 6.16797L5.30702 6.66953L7.23176 4.35984C7.58533 3.93556 8.21589 3.87824 8.64017 4.2318Z"
      fill="#212529"
    />
  </svg>
)
