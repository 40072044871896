import React, { ReactNode } from 'react'
import { ICON_TYPE } from '../Icon/Icon'
import { ActionMenu } from '../MoreMenu/ActionMenu'
import { useTranslation } from 'react-i18next'

interface LibraryCardProps {
  title: string
  date?: string
  content: ReactNode
  titleAdornment?: ReactNode
  onEdit?: () => void
  onDelete?: () => void
  size?: 'normal' | 'small'
  isAttachment?: boolean
}

export const LibraryCard: React.FC<LibraryCardProps> = ({
  title,
  date,
  content,
  titleAdornment,
  onEdit,
  onDelete,
  size = 'normal',
  isAttachment,
}) => {
  const { t } = useTranslation()

  const menuActions = [
    ...(onEdit
      ? [
          {
            label: t('common.edit', 'Edit'),
            icon: ICON_TYPE.EDIT,
            onClick: onEdit,
          },
        ]
      : []),
    ...(onDelete
      ? [
          {
            label: t('common.delete', 'Delete'),
            icon: ICON_TYPE.DELETE,
            onClick: onDelete,
            variant: 'danger' as const,
          },
        ]
      : []),
  ]

  return (
    <div
      className={`${isAttachment ? 'attachment w-100' : 'library-card'} ${size === 'small' ? 'library-card--small' : ''}`}
    >
      <div
        className={`${isAttachment ? 'attachment' : 'library-card'}__content`}
      >
        {content}
      </div>
      <div
        className={`${isAttachment ? 'attachment' : 'library-card'}__header-wrapper`}
      >
        <div
          className={`${isAttachment ? 'attachment' : 'library-card'}__header-wrapper--metadata`}
        >
          <div
            className={`${isAttachment ? 'attachment' : 'library-card'}__title-wrapper`}
          >
            {isAttachment ? (
              <a
                href={title}
                target="_blank"
                className={`${isAttachment ? 'attachment' : 'library-card'}__title`}
              >
                {title}
              </a>
            ) : (
              <div className="library-card__title">{title}</div>
            )}
            {titleAdornment}
          </div>
          {date && (
            <div
              className={`${isAttachment ? 'attachment' : 'library-card'}__date`}
            >
              {date}
            </div>
          )}
        </div>
        {(onEdit || onDelete) && (
          <div
            className={`${isAttachment ? 'attachment' : 'library-card'}__header-wrapper--menu`}
          >
            <ActionMenu actions={menuActions} />
          </div>
        )}
      </div>
    </div>
  )
}
