import { useTranslation } from 'react-i18next'
import { UserProfile } from '../../../../types/domain'
import useSettingsStore from '../store'
import EditPersonalDetailsModal from './EditPersonalDetailsModal'
import { Button } from 'react-bootstrap'
import { getHcpTypeOptions } from '../../../../types/domain'

interface Props {
  profile: UserProfile
  email: string
}

const PersonalDetails = ({ profile, email }: Props) => {
  const { t } = useTranslation()
  const { openProfileModal } = useSettingsStore()

  const displayFields = [
    {
      key: 'email',
      label: t('settings.profile.edit.email.label', 'Email'),
      value: email,
    },
    {
      key: 'firstName',
      label: t('settings.profile.edit.firstName.label', 'First name'),
    },
    {
      key: 'lastName',
      label: t('settings.profile.edit.lastName.label', 'Last name'),
    },
    {
      key: 'jobTitle',
      label: t('settings.profile.edit.jobTitle.label', 'Job Title'),
      value: getHcpTypeOptions().find(opt => opt.value === profile.jobTitle)?.label || '-'
    },
    {
      key: 'institution',
      label: t('settings.profile.edit.institution.label', 'Institution'),
    },
    {
      key: 'phoneNumber',
      label: t('settings.profile.edit.phoneNumber.label', 'Phone number'),
      value: profile.phoneNumber
        ? `${profile.phoneCountryCode || ''} ${profile.phoneNumber}`
        : '-',
    },
  ]

  return (
    <>
    <div className=" d-flex justify-content-between w-100 align-items-center">
      <div className="settings-section-title">
      {t('settings.profile.personal-details.title', 'Personal Details')}
      </div>
      <div>
      <Button
          variant="secondary"
          size="sm"
            className="mh-30px"
            onClick={() => openProfileModal(profile)}
          >
            {t('common.edit', 'Edit')}
      </Button>
      </div>
    </div>
      {displayFields.map(({ key, label, value }) => (
        <div key={key} className="settings-row">
          <div className="description">
            <div className="title">{label}</div>
          </div>
          <div className="w-50">{value || profile[key as keyof UserProfile] || '-'}</div>
        </div>
      ))}
      <EditPersonalDetailsModal />
    </>
  )
}

export default PersonalDetails
