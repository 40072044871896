import React from 'react'
import styles from './DittoSettings.module.scss'
import { DescriptionBlockProps } from './types'

export const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  title,
  description,
}) => (
  <div className={styles.settingDescription}>
    <div className={styles.settingTitle}>{title}</div>
    <div className={styles.settingSubtext}>{description}</div>
  </div>
)
