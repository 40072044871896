import { useTranslation } from 'react-i18next'
import { questionsMap, SECTIONS } from './constants'
import React from 'react';

export const FaqSection = ({ id, title }: { title: string; id: SECTIONS }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment key={id}>
      <div className="settings-section-title">{title}</div>
        {questionsMap[id]?.().map((item, i) => (
          <div key={i} className="settings-row">
            <div className='description'>

            <div className='title'>{t(item.question)}</div>
            <p className='mt-300'>{t(item.answer)}</p>
            </div>
          </div>
        ))}
    </React.Fragment>
  )
}

export default FaqSection
