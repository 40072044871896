import {
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import axios, { AxiosError, Method } from 'axios'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface AuthQueryConfig {
  baseUrl: string
  path: string
  params?: Record<string, any>
  data?: any
}

const makeAuthRequest = async (
  getToken: () => Promise<string>,
  method: HttpMethod,
  { baseUrl, path, params, data }: AuthQueryConfig,
) => {
  console.log('Making auth request:', { method, baseUrl, path })
  
  try {
    const token = await getToken()
    console.log('Request token:', token ? 'Token exists' : 'No token')

    let finalPath = path
    if (data?.id && finalPath.includes('{id}')) {
      finalPath = finalPath.replace('{id}', data.id.toString())
    }

    try {
      const response = await axios({
        method,
        url: `${baseUrl}${finalPath}`,
        params,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('Request successful:', { status: response.status, path })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle 401 Unauthorized errors
        if (error.response?.status === 401) {
          console.log('Token expired, attempting to refresh...')
          // Get a new token
          const newToken = await getToken()
          // Retry the request with the new token
          const retryResponse = await axios({
            method,
            url: `${baseUrl}${finalPath}`,
            params,
            data,
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          })
          return retryResponse.data
        }
      }
      throw error
    }
  } catch (error) {
    console.error('Request failed:', {
      error,
      method,
      path,
      response: axios.isAxiosError(error) ? error.response?.data : null
    })
    throw error
  }
}

export function useAuthQuery<TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  config: AuthQueryConfig,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } = useAuth0()
  
  return useQuery<TData, TError>({
    queryKey,
    queryFn: async () => {
      console.log('QueryFn started', { isAuthenticated, config })
      
      try {
        return await makeAuthRequest(getAccessTokenSilently, 'GET', config)
      } catch (error) {
        console.error('Auth query error:', {
          error,
          message: error instanceof Error ? error.message : 'Unknown error',
          config,
        })

        if (error instanceof Error) {
          if (error.message.includes('Missing Refresh Token')) {
            console.log('No refresh token, redirecting to landing...')
            if (!window.location.pathname.includes('/landing')) {
              window.location.href = '/'
            }
            return null
          }
        }
        throw error
      }
    },
    enabled: isAuthenticated,
    retry: 1,
    ...options,
  })
}

export function useAuthMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'POST', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAuthMutationPut<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'PUT', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAuthMutationDelete<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'DELETE', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}
