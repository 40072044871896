import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../hooks/useAuth'
import { useUpsertProfile } from '../../../../hooks/useUser/useUser'
import Modal from '../../../shared/Modal/Modal'
import styles from '../../InteractionSummary/Modal.module.scss'
import useSettingsStore from '../store'
import EditPersonalDetails from './EditPersonalDetails'

const EditPersonalDetailsModal = () => {
  const { t } = useTranslation()
  const { profileModal, closeProfileModal, isSaving, setIsSaving } =
    useSettingsStore()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutate: upsertProfile } = useUpsertProfile()

  const handleSubmit = async (data: any) => {
    if (!user?.id) return

    try {
      setIsSaving(true)
      await upsertProfile(data, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
          closeProfileModal()
        },
      })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Modal
      show={profileModal.show}
      onHide={closeProfileModal}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>
          {t('settings.profile.edit.title', 'Edit Profile')}
        </h3>
      </div>
      {profileModal.editProfile && (
        <EditPersonalDetails
          profile={profileModal.editProfile}
          onSubmit={handleSubmit}
          onCancel={closeProfileModal}
        />
      )}
    </Modal>
  )
}

export default EditPersonalDetailsModal
