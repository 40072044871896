import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../../../../hooks/useAuth'
import { useDeleteAccount } from '../../../../hooks/useUser/useUser'
import Button from '../../../core/Button/Button'
import { Modal } from 'react-bootstrap'
import styles from '../../InteractionSummary/Modal.module.scss'

const AccountDeletion = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const { user } = useAuth()
  const { mutate: deleteAccount, isPending } = useDeleteAccount()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleDeleteAccount = () => {
    deleteAccount(undefined, {
      onSuccess: () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      },
    })
  }

  return (
    <>
      <div className="settings-row">
        <div className="description">
          <div className="title">
            {t('settings.profile.remove', 'Remove Account')}
          </div>
        </div>
        <Button
          variant="danger"
          size="sm"
          className="text-danger"
          onClick={() => setShowDeleteModal(true)}
        >
          <b>{t('settings.profile.remove.button', 'Delete Account')}</b>
        </Button>

        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          dialogClassName="max-w-500px"
          contentClassName="rounded-2"
        >
          <div className={`w-100 ${styles.titleContainer}`}>
            <h3 className={styles.title}>
              {t('settings.profile.remove.confirm.title', 'Delete Account')}
            </h3>
          </div>
          <div className={styles.bodyContainer}>
            {t(
              'settings.profile.remove.confirm.message',
              'Are you sure you want to delete your account? This action cannot be undone.',
            )}
          </div>
          <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
            <Button
              variant="link"
              className={styles.button}
              onClick={() => setShowDeleteModal(false)}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              variant="danger"
              className="btn-primary"
              size="sm"
              onClick={handleDeleteAccount}
              isLoading={isPending}
            >
              {t(
                'settings.profile.remove.confirm.button',
                'Yes, Delete My Account',
              )}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default AccountDeletion
