import React from 'react'
import { useTemplates } from '../../../hooks/useTemplate/useTemplateBase'
import { Spinner } from 'react-bootstrap'
import { LibraryItemType, Template, TYPE_TRANSLATIONS } from '../../../types/domain'
import { SearchInput } from '../../core/SearchInput/SearchInput'
import { AttachmentCounter } from '../../core/AttachmentCounter/AttachmentCounter'
import { useTranslation } from 'react-i18next'

export const TemplateLibrary: React.FC = () => {
  const { t } = useTranslation()
  const { data: templates = [], isLoading } = useTemplates()
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleDragStart = (e: React.DragEvent, template: Template) => {
    if (e.dataTransfer) {
      const templateData = {
        ...template,
        attachments: template.attachments || [],
      }
      e.dataTransfer.setData('template', JSON.stringify(templateData))
    }
  }

  const getContentByType = (items: Template[], type: LibraryItemType) => {
    return items.filter((template) =>
      template.displayName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      template.type === type
    )
  }

  const renderSection = (type: LibraryItemType) => {
    const templatesForType = getContentByType(templates, type)
    
    return (
      <div className="library-list__section" key={type}>
        <div className="library-list__section-title">
          {t(TYPE_TRANSLATIONS[type])}
        </div>
        <div className="library-list__section-list-wrapper">
          {templatesForType.length > 0 ? (
            templatesForType.map((template) => (
              <div
                className="library-list__section-item-wrapper"
                key={template.id}
                draggable
                onDragStart={(e) => handleDragStart(e, template)}
              >
                <div className="library-list__section-item">
                  <div
                    className="library-list__section-item-title"
                    data-noclosesoapeditor="true"
                  >
                    {template.displayName}
                  </div>
                  <div className="library-list__section-item-attachment-counter">
                    <AttachmentCounter count={template.attachmentCount} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="library-list__empty-message">
              {t('library.type.empty-group', 'No elements available')}
            </div>
          )}
        </div>
      </div>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <div className="library-list">
      <div className="library-list-wrapper">
        <div className="library-list__search-wrapper">
          <SearchInput onChange={setSearchTerm} value={searchTerm} />
        </div>
        {renderSection('Personal' as LibraryItemType)}
        {renderSection('Organization' as LibraryItemType)}
        {renderSection('General' as LibraryItemType)}
      </div>
    </div>
  )
}
