import {create} from 'zustand';
export enum DataTransferType {
    None = 0,
    Template = 1 << 0,
    Attachment = 1 << 1
};

export type dragEventHandler = (dragEvent: React.DragEvent) => void;

interface DragAndDropState {
    dataTransferType: DataTransferType;
    isDragInProgress: boolean;
    handlers: Array<dragEventHandler>;
    attachDropHandler: (handler: dragEventHandler) => void;
    detachDropHandler: (handler: dragEventHandler) => void;
    dragStart: (type: DataTransferType) => void;
    dragStop: () => void;
    drop: (dragEvent: React.DragEvent) => void;
}

export const useDragAndDropStore = create<DragAndDropState>((set) => ({
        dataTransferType: DataTransferType.None,
        isDragInProgress: false,
        handlers: [],
        attachDropHandler: (handler: dragEventHandler) => set((state) => {
            const newHandlers = [...state.handlers];
            if(handler && !newHandlers.includes(handler))  {
                newHandlers.push(handler);
                console.log('Attached drop handler:', newHandlers);
            }   
            return ({...state, handlers: newHandlers})
        }),
        detachDropHandler: (handler: dragEventHandler) => set((state) => {
            let newHandlers = [...state.handlers];
            if(handler && newHandlers.includes(handler)) {
                const idx = newHandlers.findIndex(h => h === handler);
                newHandlers.splice(idx, 1);
                console.log('Detached drop handler', newHandlers);
            }
            return({...state, handlers: newHandlers});
        }),
        dragStart: (type: DataTransferType) => set((state: any) => ({...state, isDragInProgress: true, dataTransferType: type})),
        dragStop: () => set((state: any) => ({...state, isDragInProgress: false, dataTransferType: DataTransferType.None})),
        drop: (dragEvent: React.DragEvent) => set((state: any) => {
            if(state.handlers) {
                for (let h of state.handlers) {
                    h(dragEvent);
                }
            }
            return {...state, isDragInProgress: false}
        })
    }));