import { useEffect, useRef, useState } from 'react'
import {
  EditorState,
  convertToRaw,
  Modifier,
  convertFromHTML,
  ContentState,
  ContentBlock,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { htmlToMarkdown, markdownToHtml } from './Parser'
import { OrderedMap } from 'immutable'
import { Template } from '../../../types/domain'
import { useTranslation } from 'react-i18next'

export interface EditorContentChanged {
  html: string
  markdown: string
  hasNonWhitespaceText?: boolean
}

interface EditorAdditionalStyles {
  editor?: string
  toolbar?: string
  wrapper?: string
}

export interface EditorProps {
  value?: string
  onChange?: (changes: EditorContentChanged) => void
  placeholder?: string
  onTemplateApplied?: (template: Template) => void
  additionalStyles?: EditorAdditionalStyles 
}

export default function MarkdownEditor(props: EditorProps) {
  const isMounted = useRef(true)
  const internalValueRef = useRef<string>()
  const {i18n} = useTranslation()

  const initializeEditorState = (value?: string) => {
    if (!value) {
      return EditorState.createEmpty()
    }
    const converted = convertFromHTML(markdownToHtml(value))
    const contentState = ContentState.createFromBlockArray(
      converted.contentBlocks,
      converted.entityMap,
    )
    return EditorState.createWithContent(contentState)
  }

  const [editorState, setEditorState] = useState<EditorState>(() => {
    internalValueRef.current = props.value
    return initializeEditorState(props.value)
  })

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (props.value !== undefined && props.value !== internalValueRef.current) {
      internalValueRef.current = props.value
      const newState = initializeEditorState(props.value)
      setEditorState(newState)
    }
  }, [props.value])
  
  const onEditorStateChange = (state: EditorState) => {
    if (isMounted.current) {
      setEditorState(state)

      if (props.onChange) {
        const html = draftToHtml(convertToRaw(state.getCurrentContent()))
        const markdown = htmlToMarkdown(html)
        const hasNonWhitespaceText = state.getCurrentContent().getPlainText().trim().length > 0
        internalValueRef.current = markdown
        props.onChange({
          html,
          markdown,
          hasNonWhitespaceText
        })
      }
    }
  }

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault()
    const templateData = event.dataTransfer.getData('template')

    if (templateData) {
      try {
        const template = JSON.parse(templateData) as Template
        const htmlContent = markdownToHtml(template.content)
        const updatedEditorState = insertTextAsHTML(htmlContent, editorState)

        onEditorStateChange(updatedEditorState)

        if (props.onTemplateApplied) {
          props.onTemplateApplied(template)
        }

      } catch (error) {
        console.error('Error processing template:', error)
      }
    }
  }

  const insertTextAsHTML = (html: string, editorState: EditorState) => {
    const blocksFromHTML = convertFromHTML(html)
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()

    const newContentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )

    const finalContentState = Modifier.replaceWithFragment(
      contentState,
      selectionState,
      newContentState.getBlockMap() as OrderedMap<string, ContentBlock>,
    )

    return EditorState.push(editorState, finalContentState, 'insert-fragment')
  }

  return (
    <div
      onDrop={(event) => handleDrop(event)}
      onDragOver={(event) => event.preventDefault()}
      className="d-flex flex-column m-auto flow"
    >
      <Editor
        editorState={editorState}
        locale={i18n.language.split('-')[0] ?? 'nl'}
        onEditorStateChange={onEditorStateChange}
        placeholder={props.placeholder || 'Provide summary...'}
        toolbar={{
          options: ['inline', 'list', 'history'],
          inline: { options: ['bold', 'italic'] },
          list: { options: ['ordered'] },
        }}
        editorClassName={`editor-main ${props.additionalStyles?.editor ?? ''}`}
        toolbarClassName={`editor-toolbar ${props.additionalStyles?.toolbar ?? ''}`}
        wrapperClassName={`editor-wrapper ${props.additionalStyles?.wrapper ?? ''}`}
      />
    </div>
  )
}
