import i18n from '../../../../i18n'
import { Attachment } from '../../../../types/domain'
import { AttachmentPreview } from '../../../core/AttachmentPreview/AttachmentPreview'
import { LibraryCard } from '../../../core/LibraryCard/LibraryCard'

interface AttachmentCardProps {
  attachment: Attachment
  onEdit: (attachment: Attachment) => void
  onDelete: (id: string) => void
}

export function AttachmentCard({
  attachment,
  onEdit,
  onDelete,
}: AttachmentCardProps) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString(i18n.language, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
  }

  return (
    <LibraryCard
      title={attachment.url}
      content={
        <AttachmentPreview
          metadata={
            attachment.metadata || {
              title: '',
              description: '',
              domain: '',
            }
          }
        />
      }
      onEdit={() => onEdit(attachment)}
      onDelete={() => attachment.id && onDelete(attachment.id)}
    />
  )
}
