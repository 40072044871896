import { t } from 'i18next'
import { EN_LANGUAGE, NL_LANGUAGE } from '../i18n'

export interface Profile {
  id: string
  language: Language
  email: string
  firstName: string
  lastName: string
  jobTitle: HcpType
  phoneNumber?: PhoneNumber
  organization: Organization
  bigNumber: string
  subscription: Subscription
  institution: string
}

export interface Organization {
  id: string
  name: string
  ownerId: string
  members: Array<{
    userId: string
    role: string
  }>
}

export interface Subscription {
  verified: boolean
  tier: string
}

export interface PhoneNumber {
  countryCode: string
  number: string
}

const languages = [NL_LANGUAGE, EN_LANGUAGE] as const
export type Language = (typeof languages)[number]

export const LANGUAGE: Record<string, Language> = {
  NL: NL_LANGUAGE,
  GB: EN_LANGUAGE,
}

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>

export interface User {
  id: string
  subjectId: string
  email: string
  organizationId?: string
  roles?: Role[]
  profile?: UserProfile
}

export interface UserProfile {
  id?: string
  firstName?: string
  lastName?: string
  jobTitle?: HcpType
  language?: string
  phoneNumber?: string
  phoneCountryCode?: string
  institution?: string
}

export interface Role {
  id: string
  name: string
  description?: string
  permissions?: Permission[]
}

export interface Permission {
  id: string
  name: string
  description?: string
}

export interface Invitation {
  id: string
  hash: string
  email: string
  organizationId: string
  token: string
  invitedBy: string
  expiresAt: Date
  consumed: boolean
}

export interface Attachment {
  id: string
  displayName: string
  description: string
  url: string
  userId: string | null
  organizationId: string | null
  type: LibraryItemType
  metadata: {
    img?: string
    title?: string
    domain?: string
    favicon?: string
    description?: string
  } | null
  createdAt?: string
  updatedAt?: string
}

export interface SummaryRequest {
  medical_summary: string
  language: string
  complexity: string
}

export interface SummaryResult {
  title: string
  result: string
}

export interface Template {
  id: string
  displayName: string
  content: string
  category: number[]
  subcategory: number[]
  userId: string | null
  organizationId: string | null
  attachments: Attachment[]
  attachmentIds?: string[]
  attachmentCount: number
  type: LibraryItemType
  createdAt?: string
  updatedAt?: string
}

export interface Summary {
  displayName: string
  content: string
  category: number[]
  subcategory: number[]
  attachments: string[]
  id: string
  userId: string
  createdAt: string
  organizationId: string | null
}

export interface AuthContextType {
  user: User | null
  loading: boolean
  isAuthorized: boolean
  error?: Error
}

export interface Result<T> {
  isSuccess: boolean
  error: string | null
  value: T | null
}

export type LibraryItemType = 'Personal' | 'Organization' | 'General'

export const TYPE_TRANSLATIONS = {
  Personal: 'library.type.personal',
  Organization: 'library.type.organization',
  General: 'library.type.general',
}

export interface HcpTypeOption {
  value: number
  label: string
}

export const HCP_TYPES = {
  GENERAL_PRACTITIONER: 0,
  SPECIALIST: 1,
  NURSE: 2,
  DENTIST: 3,
  PHYSIOTHERAPIST: 4,
  PHARMACIST: 5,
  MATERNITY_CAREGIVER: 6,
  MIDWIFE: 7,
  OTHER: 8,
} as const

export type HcpType = (typeof HCP_TYPES)[keyof typeof HCP_TYPES]

export const getHcpTypeOptions = (): HcpTypeOption[] => [
  {
    value: HCP_TYPES.GENERAL_PRACTITIONER,
    label: t('hcp.type.generalPractitioner', 'General Practitioner'),
  },
  {
    value: HCP_TYPES.SPECIALIST,
    label: t('hcp.type.specialist', 'Specialist'),
  },
  { value: HCP_TYPES.NURSE, label: t('hcp.type.nurse', 'Nurse') },
  { value: HCP_TYPES.DENTIST, label: t('hcp.type.dentist', 'Dentist') },
  {
    value: HCP_TYPES.PHYSIOTHERAPIST,
    label: t('hcp.type.physiotherapist', 'Physiotherapist'),
  },
  {
    value: HCP_TYPES.PHARMACIST,
    label: t('hcp.type.pharmacist', 'Pharmacist'),
  },
  {
    value: HCP_TYPES.MATERNITY_CAREGIVER,
    label: t('hcp.type.maternityCaregiver', 'Maternity Caregiver'),
  },
  { value: HCP_TYPES.MIDWIFE, label: t('hcp.type.midwife', 'Midwife') },
  { value: HCP_TYPES.OTHER, label: t('hcp.type.other', 'Other') },
]
