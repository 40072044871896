import { FunctionComponent } from 'react'
import { UserProfile } from '../../types/domain'
import { useAuth } from '../../hooks/useAuth'
import styles from './UserLabel.module.scss'

export const UserLabel: FunctionComponent = () => {
  const { user } = useAuth()

  return (
    <>
      <div
        className={`${styles.userLabel} rounded-circle d-flex align-items-center justify-content-center me-base`}
      >
        {getAvatarLabel(user?.profile)}
      </div>
      <span className="text-truncate medium-300">
        {getUserName(user?.profile)}
      </span>
    </>
  )
}

export const getUserName = (profile?: UserProfile) =>
  profile ? `${profile.firstName ?? ''} ${profile.lastName ?? ''}` : ''

export const getAvatarLabel = (profile?: UserProfile) =>
  profile
    ? `${profile.firstName?.[0] ?? ''} ${profile.lastName?.[0] ?? ''}`
    : ''
