import { Attachment, Result } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthMutationDelete,
  useAuthMutationPost,
  useAuthQuery,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = getEnvironmentVariable('REACT_APP_API_URL') || ''

const handleResult = <T,>(result: Result<T> | undefined | null): T => {
  if (result === undefined || result === null) {
    return undefined as T
  }

  if (!result.isSuccess || result.value === null) {
    throw new Error(result.error || 'Operation failed')
  }
  return result.value
}

export const useAttachments = (params?: Record<string, any>) => {
  const result = useAuthQuery<Result<Attachment[]>>(['attachments', params], {
    baseUrl: API_BASE_URL,
    path: '/attachments',
    params,
  })

  // Transform the result to match the expected format
  return {
    ...result,
    data: result.data ? handleResult(result.data) : undefined,
  }
}

export const useUpsertAttachment = () => {
  const mutation = useAuthMutationPost<
    Result<Attachment>,
    Error,
    Partial<Attachment>
  >({
    baseUrl: API_BASE_URL,
    path: '/attachments',
  })

  // Wrap the mutateAsync to handle the Result type
  return {
    ...mutation,
    mutateAsync: async (variables: Partial<Attachment>) => {
      const result = await mutation.mutateAsync(variables)
      return handleResult(result)
    },
  }
}

export const useDeleteAttachment = () => {
  const mutation = useAuthMutationDelete<void, Error, { id: string }>({
    baseUrl: API_BASE_URL,
    path: '/attachments/{id}',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: { id: string }) => {
      await mutation.mutateAsync(variables)
      return undefined as void
    },
  }
}
