import { getDataSecurityQuestions } from './dataSecurityQuestions'
import { getTemplateQuestions } from './templateQuestions'

export enum SECTIONS {
  DATA_SECURITY = 'DATA_SECURITY',
  TEMPLATE = 'TEMPLATE',
}
export const sections: {
  id: SECTIONS
  titleKey: string
  titleFallback: string
}[] = [
  {
    id: SECTIONS.DATA_SECURITY,
    titleKey: 'support.faq.dataSecurity',
    titleFallback: 'Data security',
  },
  {
    id: SECTIONS.TEMPLATE,
    titleKey: 'support.faq.template',
    titleFallback: 'Template creation',
  },
]

export const questionsMap: Record<SECTIONS, () => Question[]> = {
  [SECTIONS.DATA_SECURITY]: getDataSecurityQuestions,
  [SECTIONS.TEMPLATE]: getTemplateQuestions,
}

export interface Question {
  question: string
  answer: string
}
