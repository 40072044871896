import React, { useEffect } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AttachmentManagement from '../components/features/ContentLibrary/AttachmentManagement/AttachmentManagement'
import TemplateManagement from '../components/features/ContentLibrary/TemplateManagement/TemplateManagement'
import useLibraryStore, { LibraryTab } from '../components/features/ContentLibrary/store'
import { Toast } from '../components/core/Toast/Toast'

const ContentLibraryPage: React.FC = () => {
  const { t } = useTranslation()
  const { 
    activeTab, 
    setActiveTab,
    toast,
    hideToast
  } = useLibraryStore()

  const onTabChange = (selectedKey: string | null) => {
    if (selectedKey) {
      setActiveTab(selectedKey as LibraryTab)
    }
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case LibraryTab.TEMPLATES:
        return <TemplateManagement />
      case LibraryTab.ATTACHMENTS:
        return <AttachmentManagement />
      default:
        return null
    }
  }

  return (
    <div className="d-flex h-100 content-library">
        {toast.show && (
          <Toast 
            variant={toast.variant} 
            message={toast.message}
          />
        )}
      <Tab.Container activeKey={activeTab} onSelect={onTabChange}>
        <Nav className="content-library__nav-tabs">
          <Nav.Item>
            <Nav.Link eventKey={LibraryTab.TEMPLATES}>
              {t('library.templates.header', 'Templates')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={LibraryTab.ATTACHMENTS}>
              {t('library.attachments.header', 'Attachments')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="content-library__content-wrapper">
          {renderTabContent()}
        </div>
      </Tab.Container>
    </div>
  )
}

export default ContentLibraryPage
