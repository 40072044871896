import { create } from 'zustand'
import { Attachment, Template } from '../../../types/domain'

export enum LibraryTab {
  TEMPLATES = 'TEMPLATES',
  ATTACHMENTS = 'ATTACHMENTS',
}

interface ModalState {
  show: boolean
  editItem: Template | Attachment | null
}

interface ToastState {
  show: boolean
  variant: 'success' | 'danger' | string
  message: string
}

interface FeatureFlags {
  isCategoryEnabled: boolean
}

interface LibraryState {
  // Navigation
  activeTab: LibraryTab
  setActiveTab: (tab: LibraryTab) => void

  // Template Modal State
  templateModal: ModalState
  openTemplateModal: (template?: Template) => void
  closeTemplateModal: () => void

  // Attachment Modal State
  attachmentModal: ModalState
  openAttachmentModal: (attachment?: Attachment) => void
  closeAttachmentModal: () => void

  // Delete Confirmation Modal
  deleteModal: {
    show: boolean
    itemToDelete: { id: string; type: 'template' | 'attachment' } | null
  }
  openDeleteModal: (id: string, type: 'template' | 'attachment') => void
  closeDeleteModal: () => void

  // Toast State
  toast: ToastState
  showToast: (variant: ToastState['variant'], message: string) => void
  hideToast: () => void

  isSaving: boolean
  setIsSaving: (loading: boolean) => void

  // Feature Flags
  featureFlags: FeatureFlags
  setFeatureFlags: (flags: FeatureFlags) => void
}

const useLibraryStore = create<LibraryState>((set) => ({
  // Feature Flags
  featureFlags: {
    isCategoryEnabled: false,
  },
  setFeatureFlags: (flags: FeatureFlags) => set({ featureFlags: flags }),

  // Navigation
  activeTab: LibraryTab.TEMPLATES,
  setActiveTab: (tab: LibraryTab) => set({ activeTab: tab }),

  // Template Modal
  templateModal: { show: false, editItem: null },
  openTemplateModal: (template?) =>
    set({
      templateModal: { show: true, editItem: template || null },
    }),
  closeTemplateModal: () =>
    set({
      templateModal: { show: false, editItem: null },
    }),

  // Attachment Modal
  attachmentModal: { show: false, editItem: null },
  openAttachmentModal: (attachment?) =>
    set({
      attachmentModal: { show: true, editItem: attachment || null },
    }),
  closeAttachmentModal: () =>
    set({
      attachmentModal: { show: false, editItem: null },
    }),

  // Delete Modal
  deleteModal: { show: false, itemToDelete: null },
  openDeleteModal: (id, type) =>
    set({
      deleteModal: { show: true, itemToDelete: { id, type } },
    }),
  closeDeleteModal: () =>
    set({
      deleteModal: { show: false, itemToDelete: null },
    }),

  // Toast State
  toast: {
    show: false,
    variant: '',
    message: '',
  },
  showToast: (variant, message) => {
    set((state) => ({
      ...state,
      toast: {
        show: true,
        variant,
        message,
      },
    }))

    setTimeout(() => {
      set((state) => ({
        ...state,
        toast: {
          show: false,
          variant: '',
          message: '',
        },
      }))
    }, 3000)
  },
  hideToast: () =>
    set((state) => ({
      ...state,
      toast: {
        show: false,
        variant: '',
        message: '',
      },
    })),

  isSaving: false,
  setIsSaving: (saving: boolean) => set({ isSaving: saving }),
}))

export default useLibraryStore
