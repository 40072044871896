import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../../../../hooks/useAuth'
import { useResetPassword } from '../../../../hooks/useUser/useUser'
import { Toast } from '../../../core/Toast/Toast'
import Button from '../../../core/Button/Button'

const LoginInformation = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const { user } = useAuth()
  const { mutate: resetPassword } = useResetPassword()
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleResetPassword = async () => {
    if (!user?.id) return

    resetPassword(undefined, {
      onSuccess: () => {
        setToastConfig({
          show: true,
          variant: 'success',
          message: t(
            'settings.profile.password.reset.toast.success',
            'Password reset email sent',
          ),
        })
        // Logout user after showing success message
        setTimeout(() => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        }, 2000)
      },
      onError: () => {
        setToastConfig({
          show: true,
          variant: 'danger',
          message: t(
            'settings.profile.password.reset.toast.error',
            'Failed to reset password',
          ),
        })
      },
    })
  }

  return (
    <>
      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
      <div className="settings-section-title">
        {t('settings.profile.password.reset.title', 'Login Information')}
      </div>
      <div className="settings-row">
        <div className="description w-70">
          <div className="subtext">
            {t(
              'settings.profile.password.reset.description',
              'Use the button below to update your password. You will be logged out after requesting a password change.',
            )}
          </div>
        </div>
        <div>
          <Button variant="secondary" size="sm" onClick={handleResetPassword}>
            {t('settings.profile.password.reset.button', 'Reset Password')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default LoginInformation
