import React from 'react'
import Modal from '../../shared/Modal/Modal'
import Button from '../Button/Button'
import styles from '../../features/InteractionSummary/Modal.module.scss'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  message: string
  title?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  message,
  title = 'Confirm Action',
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.bodyContainer}>
        <p>{message}</p>
      </div>
      <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
        <Button variant="link" type="button" onClick={onHide}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="btn-primary"
          onClick={() => {
            onConfirm()
            onHide()
          }}
        >
          {t('common.confirm', 'Confirm')}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
