import React, { useEffect, useMemo, useState } from 'react'
import styles from './RecordingOptions.module.scss'
import { ToggleOption } from './ToggleOption'
import { SelectField } from './SelectField'
import { useTranslation } from 'react-i18next'
import Button from '../../../core/Button/Button'
import useStore, { Page } from '../store'
import { useAiApiQuery } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { EMPTY_CONSULT } from '../../../../utils/constants'
import { Preferences } from '../../Settings/ProfileSettings/AiSettings/types'

export const RecordingOptions: React.FC = () => {
  const approveRecording = useStore((state) => state.approveRecording)
  const setapproveRecording = useStore((state) => state.setApproveRecording)
  const anonymization = useStore((state) => state.anonymization)
  const setAnonymization = useStore((state) => state.setAnonymization)
  const protocol = useStore((state) => state.protocol)
  const setProtocol = useStore((state) => state.setProtocol)
  const consultId = useStore((state) => state.consultId)
  const setPage = useStore((state) => state.setPage)
  const setConsultId = useStore((state) => state.setConsultId)
  const [toggleStates, setToggleStates] = useState({
    recording: true,
    anonymization: true,
    /*userAbbreviations: true,
    explainAbbreviations: true,*/
  })
  useEffect(() => {
    setapproveRecording(false)
  }, [])
  const { t } = useTranslation()
  const { data = [] } = useAiApiQuery(['protocols'], {
    path: '/protocol/',
  })
  const { data: dataPreference } = useAiApiQuery<Preferences>(['preference'], {
    path: '/user/preference',
  })
  const options = useMemo(
    () =>
      (data as { name: string }[]).map((el) => ({
        value: el.name,
        label: el.name,
      })),
    [data],
  )
  useEffect(() => {
    if (options) {
      setProtocol(dataPreference?.default_protocol || options[0]?.value)
    }
  }, [options, dataPreference])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('consult.recording.options', 'Recording options')}
          </h2>
        </div>
        <div className={styles.protocolSection}>
          <div className={styles.protocolHeader}>
            <div className={styles.protocolTitle}>
              {t('consult.protocol.type', 'Protocol type')}
            </div>
            <div className={styles.protocolDescription}>
              {t(
                'consult.protocol.description',
                'Select the desired reporting protocol',
              )}
            </div>
          </div>
          <SelectField
            options={options}
            onChange={(val) => setProtocol(val)}
            value={protocol}
            isDisabled={!approveRecording}
          />
        </div>

        <ToggleOption
          key={'recording'}
          title={t('consult.recording-approval.label', 'Recording approval')}
          description={t(
            'consult.recording-approval.description',
            'Patient agrees with using Ditto',
          )}
          Off={!approveRecording}
          Disabled={false}
          onToggle={() => setapproveRecording(!approveRecording)}
        />

        <ToggleOption
          key="anonymization"
          title={t('consult.anonymization.label', 'Anonimization')}
          description={t(
            'consult.anonymization.description',
            'Anonimize personal information',
          )}
          Off={!anonymization}
          Disabled={!approveRecording}
          onToggle={() => setAnonymization(!anonymization)}
        />
      </div>
      {consultId > EMPTY_CONSULT ? (
        <Button
          onClick={() => {
            setConsultId(EMPTY_CONSULT)
            setPage(Page.newAi)
          }}
          className={`w-100`}
          data-testid="new_recording"
          type="submit"
          variant="primary"
          disabled={!toggleStates.recording}
        >
          {t('consult.new-recording.button', 'New recording')}
        </Button>
      ) : null}
    </>
  )
}
