import { create } from 'zustand'
import { EMPTY_CONSULT } from '../../../utils/constants'
import { Attachment } from '../../../types/domain'

export enum Page {
  config = 0,
  newAi,
  newManual,
  aiRecordingInProgress,
  aiRecordingSummary,
}

interface TranscriptSegment {
  text: string
  speaker: number
  timestamp: number
}

export enum NavigationTab {
  OPTIONS = 'consult.navigation.options',
  TEMPLATES = 'consult.navigation.templates',
  FILES = 'consult.navigation.files',
}

export interface NavigationItem {
  label: NavigationTab
  isVisible: boolean
  isActive: boolean
  disabled?: boolean
}

export type Summaries = {
  protocol_name: string
  content: { field_name: string; content: string }[]
}[]

export interface Transcript {
  title: string
  transcript_raw: {
    segments: TranscriptSegment[]
  }
  transcript_anonymized: {
    segments: TranscriptSegment[]
  }
  summaries: Summaries
}

interface ConsultState {
  page: Page
  approveRecording: boolean
  anonymization: boolean
  consultId: number
  protocol: string
  deviceId: string
  transcript: Transcript
  editedSummaries: Summaries
  sharedAttachments: Attachment[]
  editedFieldName: string
  navigationState: NavigationTab
  setTranscript: (rec: Transcript) => void
  setEditedFieldName: (editedFieldName: string) => void
  setSharedAttachments: (sharedAttachments: Attachment[]) => void
  setEditedSummaries: (editedSummaries: Summaries) => void
  setApproveRecording: (rec: boolean) => void
  setAnonymization: (anon: boolean) => void
  setProtocol: (rec: string) => void
  setDeviceId: (deviceId: string) => void
  setConsultId: (consultId: number) => void
  setPage: (page: Page) => void
  back: () => void
  setNavigationState: (state: NavigationTab) => void
  initializeNavigationState: (page: Page) => void
}

const useStore = create<ConsultState>((set) => ({
  page: Page.config,
  approveRecording: false,
  consultId: EMPTY_CONSULT,
  anonymization: false,
  deviceId: '',
  editedSummaries: [],
  sharedAttachments: [],
  transcript: {
    title: '',
    transcript_raw: {
      segments: [],
    },
    transcript_anonymized: {
      segments: [],
    },
    summaries: [
      {
        protocol_name: 'SOAP',
        content: [],
      },
      {
        protocol_name: 'PAT',
        content: [],
      },
    ],
  },
  editedFieldName: '',
  protocol: '',
  navigationState: NavigationTab.OPTIONS,
  setEditedFieldName: (editedFieldName: string) =>
    set(() => ({ editedFieldName })),
  setTranscript: (transcript: Transcript) => set(() => ({ transcript })),
  setSharedAttachments: (sharedAttachments: Attachment[]) =>
    set(() => ({ sharedAttachments })),
  setEditedSummaries: (editedSummaries: Summaries) =>
    set(() => ({ editedSummaries })),
  setConsultId: (consultId: number) => set(() => ({ consultId })),
  setDeviceId: (deviceId: string) => set(() => ({ deviceId })),
  setProtocol: (protocol: string) => set(() => ({ protocol })),
  setApproveRecording: (approveRecording: boolean) =>
    set(() => ({ approveRecording })),
  setAnonymization: (anonymization: boolean) => set(() => ({ anonymization })),
  setPage: (page: Page) => {
    set(() => ({
      page,
      navigationState:
        page === Page.newManual
          ? NavigationTab.TEMPLATES
          : NavigationTab.OPTIONS,
    }))
  },
  back: () =>
    set((state) => ({
      page: state.page - 1,
      navigationState:
        state.page - 1 === Page.newManual
          ? NavigationTab.TEMPLATES
          : NavigationTab.OPTIONS,
    })),
  setNavigationState: (navigationState: NavigationTab) =>
    set(() => ({ navigationState })),
  initializeNavigationState: (page: Page) =>
    set(() => ({
      navigationState:
        page === Page.newManual
          ? NavigationTab.TEMPLATES
          : NavigationTab.OPTIONS,
    })),
}))

export default useStore
