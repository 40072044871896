import React, { useMemo, useState } from 'react'
import styles from './DittoSettings.module.scss'
import { SegmentedButtons } from './SegmentedButtons'
import { Preferences, SettingProps } from './types'
import {
  useAiApiQuery,
  useAiMutationPost,
} from '../../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { DescriptionBlock } from './DescriptionBlock'
import { SelectField } from '../../../Consult/AiConsultForm/SelectField'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export const AiSettings: React.FC = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { data = [] } = useAiApiQuery(['protocols'], {
    path: '/protocol/',
  })
  const { data: dataPreference } = useAiApiQuery<Preferences>(['preference'], {
    path: '/user/preference',
  })
  const prefsMutation = useAiMutationPost({
    path: '/user/preference',
  })

  const options = useMemo(
    () =>
      (data as { name: string }[]).map((el) => ({
        value: el.name,
        label: el.name,
      })),
    [data],
  )

  const settings: SettingProps[] = [
    {
      type: 'segmented',
      props: {
        title: t('aisettings.complexity.title', 'Complexity'),
        description: t(
          'aisettings.complexity.description',
          'Complexity of the Ai analysis',
        ),
        options: [
          {
            label: 'Accessible',
            value: 'accessible',
            isActive: dataPreference?.default_complexity === 'accessible',
          },
          {
            label: 'Balanced',
            value: 'balanced',
            isActive: dataPreference?.default_complexity === 'balanced',
          },
          {
            label: 'Technical',
            value: 'technical',
            isActive: dataPreference?.default_complexity === 'technical',
          },
        ],
        onClick: async (val) => {
          await prefsMutation.mutateAsync({
            ...dataPreference,
            default_complexity: val,
          })
          await queryClient.invalidateQueries({
            queryKey: ['preference'],
            exact: true,
          })
        },
      },
    },
    {
      type: 'segmented',
      props: {
        title: t('aisettings.conciseness.title', 'Conciseness'),
        description: t(
          'aisettings.conciseness.description',
          'Conciseness of the Ai analysis',
        ),
        options: [
          {
            label: 'Brief',
            value: 'brief',
            isActive: dataPreference?.default_conciseness === 'brief',
          },
          {
            label: 'Balanced',
            value: 'balanced',
            isActive: dataPreference?.default_conciseness === 'balanced',
          },
          {
            label: 'Extensive',
            value: 'extensive',
            isActive: dataPreference?.default_conciseness === 'extensive',
          },
        ],
        onClick: async (val) => {
          await prefsMutation.mutateAsync({
            ...dataPreference,
            default_conciseness: val,
          })
          await queryClient.invalidateQueries({
            queryKey: ['preference'],
            exact: true,
          })
        },
      },
    },
  ]

  return (
    <div className={styles.settingsContainer}>
      <h1 className={styles.title}>Ditto AI</h1>
      <div className={styles.settingRow}>
        <DescriptionBlock
          title={t('aisettings.protocol.title', 'Default protocol type')}
          description={t(
            'aisettings.protocol.description',
            'Protocol for AI analysis',
          )}
        />
        <div className={styles.dropdownContainer}>
          <SelectField
            options={options}
            onChange={async (val) => {
              await prefsMutation.mutateAsync({
                ...dataPreference,
                default_protocol: val,
              })
              await queryClient.invalidateQueries({
                queryKey: ['preference'],
                exact: true,
              })
            }}
            value={dataPreference?.default_protocol}
          />
        </div>
      </div>
      {settings.map((setting, index, arr) => (
        <React.Fragment key={index}>
          <SegmentedButtons
            {...(setting.props as any)}
            isLast={index === arr.length - 1}
          />
        </React.Fragment>
      ))}
    </div>
  )
}
