import React, { useState, useRef, ReactNode } from 'react'
import Icon, { ICON_TYPE } from '../Icon/Icon'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { ActionMenuItem } from './ActionMenuItem'
import useStore from '../../features/Consult/store'

interface Action {
  label: string
  icon?: ICON_TYPE
  onClick: () => void
  variant?: 'danger' | 'default'
}

interface ActionMenuProps {
  actions: Action[]
  children?: ReactNode
  isButton?: boolean
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  actions,
  children,
  isButton = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const editedSummaries = useStore((state) => state.editedSummaries)

  useOnClickOutside(menuRef, () => setIsOpen(false))

  return (
    <div className="more-menu" ref={menuRef}>
      {isButton ? (
        <button
          className="more-menu__trigger"
          onClick={() => setIsOpen(!isOpen)}
        >
          {children ?? <Icon icon={ICON_TYPE.MORE_VERTICAL} />}
        </button>
      ) : (
        <div className="more-menu__trigger" onClick={() => setIsOpen(!isOpen)}>
          {children ?? <Icon icon={ICON_TYPE.MORE_VERTICAL} />}
        </div>
      )}

      {isOpen && (
        <div className="more-menu__content">
          {actions.map((action, index) => (
            <ActionMenuItem
              key={index}
              label={action.label}
              icon={action.icon}
              onClick={() => {
                action.onClick()
                setIsOpen(false)
              }}
              variant={action.variant}
            />
          ))}
        </div>
      )}
    </div>
  )
}
