import { UseQueryOptions } from '@tanstack/react-query'
import { HcpType, Result } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import { useMutationPost, useQueryGet } from '../useQuery/useQuery'

const API_BASE_URL = getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL')

interface RegistrationResponse {
  message: string
  userId: string
  auth0UserId: string
}

export interface EmailCheckResponse {
  exists: boolean
  isAllowedDomain: boolean
}

export interface RegistrationDto {
  id: string
  email: string
  password: string
  isTermsAccepted: boolean
  firstName: string
  lastName: string
  jobTitle?: HcpType
  institution?: string
  bigNumber?: string
  organizationId?: string
  phoneNumber?: string
  phoneCountryCode?: string
  invitationId?: string
  language?: string
  roleIds?: string[]
}

export const useRegisterUser = () => {
  return useMutationPost<RegistrationResponse, Error, RegistrationDto>({
    baseUrl: API_BASE_URL || '',
    path: '/register',
  })
}

export const useCheckEmail = (
  email?: string,
  options?: Omit<
    UseQueryOptions<Result<EmailCheckResponse>, unknown>,
    'queryFn' | 'queryKey'
  >,
) => {
  return useQueryGet<Result<EmailCheckResponse>>(
    {
      baseUrl: API_BASE_URL || '',
      path: `/register/check-email`,
      params: { email },
    },
    options,
  )
}
