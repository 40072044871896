import { Question } from './constants'

const dataSecurityQuestions: Question[] = [
  {
    question: 'support.faq.dataSecurity.question.a1',
    answer: 'support.faq.dataSecurity.answer.a1',
  },
  {
    question: 'support.faq.dataSecurity.question.a2',
    answer: 'support.faq.dataSecurity.answer.a2',
  },
  {
    question: 'support.faq.dataSecurity.question.a3',
    answer: 'support.faq.dataSecurity.answer.a3',
  },
]

export const getDataSecurityQuestions = () => dataSecurityQuestions
