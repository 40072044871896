import axios from 'axios'
import {
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  useQuery,
  useMutation,
} from '@tanstack/react-query'

interface QueryConfig {
  baseUrl: string
  path: string | ((variables: any) => string)
  params?: Record<string, any>
  data?: any
}

const makeRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  { baseUrl, path, params, data }: QueryConfig,
) => {
  try {
    const finalPath = typeof path === 'function' ? path(data) : path
    const response = await axios({
      method,
      url: `${baseUrl}${finalPath}`,
      params,
      data,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios response data:', error.response?.data)
      throw error.response?.data
    }
    throw error
  }
}

export function useMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<QueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeRequest('POST', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useQueryGet<
  TData = unknown,
  TError = unknown,
>(
  config: QueryConfig,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryFn' | 'queryKey'>
) {
  const queryKey = Array.isArray(config.path) 
    ? ['query', ...config.path]
    : ['query', config.path, config.params]

  return useQuery<TData, TError>({
    queryKey,
    queryFn: async () => {
      const response = await makeRequest('GET', config)
      return response as TData
    },
    ...options
  })
}
