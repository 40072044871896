import { Question } from './constants'

const templateQuestions: Question[] = [
  {
    question: 'support.faq.template.question.a1',
    answer: 'support.faq.template.answer.a1',
  },
  {
    question: 'support.faq.template.question.a2',
    answer: 'support.faq.template.answer.a2',
  },
  {
    question: 'support.faq.template.question.a3',
    answer: 'support.faq.template.answer.a3',
  },
]

export const getTemplateQuestions = () => templateQuestions
