import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../hooks/useAuth'
import { useUpsertProfile } from '../../../hooks/useUser/useUser'
import Button from '../../core/Button/Button'
import { Toast } from '../../core/Toast/Toast'
import Modal from '../../shared/Modal/Modal'
import styles from '../InteractionSummary/Modal.module.scss'
import { LanguageSwitcher } from './LanguageSwitcher'

interface LanguageSwitcherModalProps {
  isShow: boolean
  onHide: () => void
}

export function LanguageSwitcherModal({
  isShow,
  onHide,
}: LanguageSwitcherModalProps) {
  const [lng, setLng] = useState('')
  const { user } = useAuth()
  const { t, i18n } = useTranslation()
  const queryClient = useQueryClient()
  const { mutate: upsertProfile } = useUpsertProfile()
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleChangeLng = useCallback(() => {
    if (!lng) return

    upsertProfile(
      { id: user?.profile?.id, language: lng },
      {
        onSuccess: async () => {
          localStorage.setItem('selectedLanguage', lng)
          await i18n.changeLanguage(lng)
          await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
          setToastConfig({
            show: true,
            variant: 'success',
            message: t(
              'language.change.success',
              'Language updated successfully',
            ),
          })
          onHide()
        },
        onError: () => {
          setToastConfig({
            show: true,
            variant: 'danger',
            message: t('language.change.error', 'Failed to update language'),
          })
        },
      },
    )
  }, [lng, i18n, upsertProfile, queryClient, onHide, t])

  return (
    <>
      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
      <Modal
        show={isShow}
        dialogClassName="max-w-500px"
        contentClassName="rounded-2"
        onHide={onHide}
      >
        <div className={`w-100 ${styles.titleContainer}`}>
          <h3 className={styles.title}>
            {t(
              'language.select.description',
              'Please select the language you want to use',
            )}
          </h3>
        </div>
        <div className={styles.bodyContainer}>
          <LanguageSwitcher onChange={setLng} value={lng} />
        </div>
        <div
          className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
        >
          <Button variant="link" type="button" onClick={onHide}>
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            type="submit"
            size="sm"
            className="btn-primary"
            onClick={handleChangeLng}
            disabled={!lng}
          >
            {t('common.confirm', 'Confirm')}
          </Button>
        </div>
      </Modal>
    </>
  )
}
