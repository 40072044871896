import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import FaqSection from './FaqSection'
import { sections } from './constants'
import useSettingsStore from '../store'
import ArrowLeft from '../../../core/Icon/icons/svg/ArrowLeft.svg'

const FaqContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { setSupportSubPage } = useSettingsStore()

  const handleBack = () => {
    if (location.pathname.includes('/settings')) {
      setSupportSubPage('main')
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="settings-content" data-testid="faq">
      <div className="settings-top-link cursor-pointer" onClick={handleBack} >
        <img alt="back" src={ArrowLeft} />
        {t('common.back', 'Back')}
      </div>
      <div className="settings-title">{t('support.faq.title', 'FAQs')}</div>
      {sections.map(({ titleKey, titleFallback, id }) => (
        <FaqSection id={id} title={t(titleKey, titleFallback)} key={titleKey} />
      ))}
    </div>
  )
}

export default FaqContainer
