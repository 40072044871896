import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../hooks/useAuth'
import { useUpsertProfile } from '../../../../hooks/useUser/useUser'
import { Toast } from '../../../core/Toast/Toast'
import { LanguageSwitcher } from '../../LanguageSwitcher/LanguageSwitcher'

const LanguageSettings = () => {
  const [lng, setLng] = useState('')
  const { t, i18n } = useTranslation()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutate: upsertProfile } = useUpsertProfile()
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleChangeLng = useCallback(
    (newLng: string) => {
      if (!newLng) return

      upsertProfile(
        { id: user?.profile?.id, language: newLng },
        {
          onSuccess: async () => {
            localStorage.setItem('selectedLanguage', newLng)
            await i18n.changeLanguage(newLng)
            await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
            setToastConfig({
              show: true,
              variant: 'success',
              message: t(
                'language.change.success',
                'Language updated successfully',
              ),
            })
          },
          onError: () => {
            setToastConfig({
              show: true,
              variant: 'danger',
              message: t('language.change.error', 'Failed to update language'),
            })
          },
        },
      )
    },
    [i18n, upsertProfile, queryClient, t],
  )

  return (
    <>
      <div className="settings-section-title">
        {t('settings.profile.language.title', 'Language Settings')}
      </div>
      <div className="settings-row">
        <div className="description">
          <div className="subtext">
            {t(
              'settings.profile.language.description',
              'Select your preferred language',
            )}
          </div>
        </div>
        <div>
          <LanguageSwitcher
            onChange={(value) => {
              setLng(value)
              handleChangeLng(value)
            }}
            value={lng || user?.profile?.language || i18n.language}
          />
        </div>
      </div>

      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
    </>
  )
}

export default LanguageSettings
