import React, { useState } from 'react'
import Modal from '../../shared/Modal/Modal'
import Button from '../../core/Button/Button'
import { Form } from 'react-bootstrap'
import { t } from 'i18next'
import styles from './Modal.module.scss'
import { ActionButton } from '../Consult/ActionButtons/ActionButton'
import {
  getNormalizedUrl,
  validateAttachmentUrl,
} from '../../../utils/urlUtils'

interface AddLinkModalProps {
  show: boolean
  onHide: () => void
  onAdd: (url: string) => void
}

const AddLinkModal: React.FC<AddLinkModalProps> = ({ show, onHide, onAdd }) => {
  const [url, setUrl] = useState('')
  const [isUrlValid, setIsUrlValid] = useState(true)

  const validateUrl = (url: string) => {
    const isValid = validateAttachmentUrl(url)
    setIsUrlValid(isValid)
    return isValid
  }

  const handleAdd = () => {
    if (url && validateUrl(url)) {
      onAdd(getNormalizedUrl(url))
      setUrl('')
      setIsUrlValid(true)
      onHide()
    }
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value
    setUrl(newUrl)
    if (newUrl) {
      validateUrl(newUrl)
    } else {
      setIsUrlValid(true)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>
          {t('consult.manual.add-link.modal.title', 'New link')}
        </h3>
      </div>
      <Form.Group className={`mb-3 ${styles.bodyContainer}`}>
        <Form.Label>{t('consult.manual.add-link.modal.url', 'URL')}</Form.Label>
        <Form.Control
          type="text"
          value={url}
          onChange={handleUrlChange}
          placeholder={t(
            'consult.manual.add-link.modal.placeholder',
            'Enter here',
          )}
          isInvalid={!isUrlValid}
        />
        {!isUrlValid && (
          <Form.Control.Feedback type="invalid">
            {t(
              'consult.manual.add-link.modal.invalid-url',
              'Please enter a valid URL',
            )}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
        <Button variant="link" onClick={onHide} className={styles.button}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <ActionButton
          variant="primary"
          disabled={!url || !isUrlValid}
          onClick={handleAdd}
          label={t('common.add', 'Add')}
        />
      </div>
    </Modal>
  )
}

export default AddLinkModal
