import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useDebounce } from '../../../../hooks/useDebounce'
import {
  RegistrationDto,
  useCheckEmail,
  useRegisterUser,
} from '../../../../hooks/useRegistration/useRegistration'
import { getHcpTypeOptions, HcpType } from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import ArrowLeft from '../../../core/Icon/icons/svg/ArrowLeft.svg'
import { PasswordInput } from '../../../core/PasswordInput/PasswordInput'
import { Select } from '../../../core/Select/Select'
import { Toast } from '../../../core/Toast/Toast'
import LegalLinks from '../../../shared/LegalLinks'
import { AuthContainer } from '../AuthContainer'
import {
  ERROR_MESSAGE,
  hasLength,
  validatePassword,
} from './password_constants'
import { PasswordRequirements } from './PasswordRequirements'

interface RegistrationError extends Error {
  errorTranslationCode?: string
}

export type RegistrationForm = {
  id: string
  email: string
  bigNumber: string
  institution: string
  organization?: {
    id: string
    name: string
    role: string
  }
  password: string
  confirmPassword: string
  isTermsAccepted: boolean
  firstName: string
  lastName: string
  jobTitle: HcpType
  phoneNumber?: {
    countryCode: string
    number: string
  }
}

export function Registration() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState<string | null>(null)
  const [invitationData, setInvitationData] = useState<any>(null)

  const methods = useForm<RegistrationForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      id: uuidv4(),
      email: '',
      bigNumber: '',
      organization: undefined,
      confirmPassword: '',
      password: '',
      isTermsAccepted: false,
      firstName: '',
      lastName: '',
      jobTitle: undefined,
      phoneNumber: { countryCode: '', number: '' },
      institution: '',
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    trigger,
    setValue,
  } = methods

  const password = watch('password')

  const invitationId = new URLSearchParams(location.search).get('invitationId')

  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const debouncedEmail = useDebounce(watch('email'), 750)
  const { data: emailCheckResult, refetch: checkEmail } = useCheckEmail(
    debouncedEmail,
    {
      enabled: false,
    },
  )

  useEffect(() => {
    if (debouncedEmail && !invitationData) {
      setIsCheckingEmail(true)
      checkEmail()
        .then(() => {
          trigger('email')
        })
        .finally(() => {
          setIsCheckingEmail(false)
        })
    } else {
      setIsCheckingEmail(false)
    }
  }, [debouncedEmail, checkEmail, invitationData, trigger])

  useEffect(() => {
    if (invitationData) {
      setValue('email', invitationData.email || '', { shouldDirty: true })
      setValue('organization.name', invitationData.organizationName || '', {
        shouldDirty: true,
      })
      setValue('organization.id', invitationData.organizationId || '', {
        shouldDirty: true,
      })
      setValue('organization.role', invitationData.role || '', {
        shouldDirty: true,
      })
    }
  }, [invitationData, setValue])

  const {
    mutate: registerUser,
    isPending,
    error: submitError,
  } = useRegisterUser()

  const onSubmit = async (data: RegistrationForm) => {
    try {
      const registrationData: RegistrationDto = {
        id: data.id,
        email: data.email,
        password: data.password,
        isTermsAccepted: data.isTermsAccepted,
        firstName: data.firstName,
        lastName: data.lastName,
        jobTitle: data.jobTitle || undefined,
        bigNumber: data.bigNumber || undefined,
        institution: data.institution || undefined,
        organizationId: data.organization?.id
          ? data.organization.id
          : undefined,
        phoneNumber: data.phoneNumber?.number || undefined,
        phoneCountryCode: data.phoneNumber?.countryCode || undefined,
        invitationId: invitationId || undefined,
        language: i18n.language,
        roleIds: data.organization?.role ? [data.organization.role] : undefined,
      }

      await registerUser(registrationData, {
        onSuccess: (response) => {
          navigate('/')
        },
        onError: (error: RegistrationError) => {
          if (error.message) {
            setError(
              error.errorTranslationCode
                ? t(error.errorTranslationCode)
                : error.message ||
                    t(
                      'registration.error',
                      "We're sorry, but we couldn't process your request. Please try again later.",
                    ),
            )
          }
        },
      })
    } catch (error) {
      setError(
        t(
          'registration.error',
          "We're sorry, but we couldn't process your request. Please try again later.",
        ),
      )
    }
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [error])

  return (
    <AuthContainer>
      {error && <Toast variant="danger" message={error} />}
      <a
        href="#"
        onClick={() => history.back()}
        className="d-flex justify-content-center align-items-center"
      >
        <img alt="" src={ArrowLeft} /> {t('common.back', 'Back')}
      </a>
      <h1 className="h4">{t('common.signup', 'Sign up')}</h1>
      <FormProvider {...methods}>
        <Form noValidate className="w-100">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                match: (v) =>
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v),
                available: () => !emailCheckResult?.value?.exists,
                allowedDomain: () =>
                  emailCheckResult?.value?.isAllowedDomain ?? true,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.email.label', 'Email')}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    if (error) {
                      trigger('email')
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur()
                    trigger('email')
                  }}
                  className={`${error ? 'is-invalid' : ''}`}
                  disabled={!!invitationData}
                  value={invitationData?.email || field.value}
                />
                {error && (
                  <div className="invalid-feedback">
                    {error.type === 'available' &&
                      t(
                        'registration.email.exists',
                        'This email is already registered',
                      )}
                    {error.type === 'allowedDomain' &&
                      t(
                        'registration.email.domain-not-allowed',
                        'This email domain is not allowed',
                      )}
                    {error.type === 'match' &&
                      t(
                        'registration.email.invalid',
                        'Please enter a valid email address',
                      )}
                    {error.type === 'required' &&
                      t('registration.email.required', 'Email is required')}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="firstName"
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.firstName.label', 'First Name')}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  {...field}
                  className={`${error ? 'is-invalid' : ''}`}
                  placeholder={t(
                    'registration.firstName.placeholder',
                    'Enter your first name',
                  )}
                />
                {error && (
                  <div className="invalid-feedback">
                    {t(
                      'registration.firstName.required',
                      'First name is required',
                    )}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="lastName"
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.lastName.label', 'Last Name')}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  {...field}
                  className={`pe-700 ${error ? 'is-invalid' : ''}`}
                  placeholder={t(
                    'registration.lastName.placeholder',
                    'Enter your last name',
                  )}
                />
                {error && (
                  <div className="invalid-feedback">
                    {t(
                      'registration.lastName.required',
                      'Last name is required',
                    )}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="jobTitle"
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.jobTitle.label', 'Job Title')}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  options={getHcpTypeOptions()}
                  value={getHcpTypeOptions().find(
                    (opt) => opt.value === field.value,
                  )}
                  onChange={(option) => field.onChange(option?.value)}
                  placeholder={t(
                    'registration.jobTitle.placeholder',
                    'Select your job title',
                  )}
                  isSearchable={false}
                  className={error ? 'is-invalid' : ''}
                />
                {error && (
                  <div className="invalid-feedback">
                    {t(
                      'registration.jobTitle.required',
                      'Job title is required',
                    )}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            control={control}
            name="phoneNumber.countryCode"
            rules={{
              pattern: {
                value: /^\d+$/,
                message: t(
                  'registration.phoneNumber.countryCode.numeric',
                  'Only numbers allowed',
                ),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t(
                    'registration.phoneNumber.countryCode.label',
                    'Country Code',
                  )}
                </label>
                <Form.Control
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '')
                    field.onChange(value)
                  }}
                  className={`pe-700 ${error ? 'is-invalid' : ''}`}
                  placeholder={t(
                    'registration.phoneNumber.countryCode.placeholder',
                    'ex.: 31',
                  )}
                />
                {error && (
                  <div className="invalid-feedback">{error.message}</div>
                )}
              </div>
            )}
          />
          <Controller
            control={control}
            name="phoneNumber.number"
            rules={{
              pattern: {
                value: /^\d+$/,
                message: t(
                  'registration.phoneNumber.number.numeric',
                  'Only numbers allowed',
                ),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.phoneNumber.number.label', 'Phone Number')}
                </label>
                <Form.Control
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '')
                    field.onChange(value)
                  }}
                  className={`pe-700 ${error ? 'is-invalid' : ''}`}
                  placeholder={t(
                    'registration.phoneNumber.number.placeholder',
                    'Enter your phone number',
                  )}
                />
                {error && (
                  <div className="invalid-feedback">{error.message}</div>
                )}
              </div>
            )}
          />
          <Controller
            rules={{
              required: true,
              validate: {
                hasLength: (v) => v?.length === 11 || ERROR_MESSAGE,
                isNumeric: (v) => /^\d+$/.test(v) || ERROR_MESSAGE,
              },
            }}
            control={control}
            name="bigNumber"
            render={({ field, fieldState: { error } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.bigNumber.label', 'BIG number')}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  className={`pe-700 ${error ? 'is-invalid' : ''}`}
                  {...field}
                  placeholder={t(
                    'registration.bigNumber.placeholder',
                    'Enter your BIG number',
                  )}
                />
                {error && (
                  <div className="invalid-feedback">
                    {t(
                      'registration.bigNumber.invalid',
                      'Please enter a valid 11-digit BIG number',
                    )}
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            control={control}
            name="institution"
            render={({ field }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.institution.label', 'Institution')}
                </label>
                <Form.Control
                  className="pe-700"
                  {...field}
                  placeholder={t(
                    'registration.institution.placeholder',
                    'Enter your institution',
                  )}
                />
              </div>
            )}
          />
          {!!invitationData && (
            <>
              {/* Organization Name Field (Visible to User) */}
              <Controller
                control={control}
                name="organization.name"
                rules={{
                  required: !!invitationData,
                }}
                render={({ field }) => (
                  <div className="position-relative mt-600">
                    <Form.Control
                      className="pe-700"
                      {...field}
                      placeholder={t(
                        'registration.organization',
                        'Organization',
                      )}
                      disabled={!!invitationData}
                      value={
                        invitationData.organizationName || field.value || ''
                      }
                      onChange={(e) => {
                        const nameValue = e.target.value || ''
                        field.onChange(nameValue)
                      }}
                    />
                  </div>
                )}
              />

              {/* Hidden Organization ID Field */}
              <Controller
                control={control}
                name="organization.id"
                render={({ field }) => (
                  <input
                    type="hidden"
                    {...field}
                    value={invitationData.organizationId || ''}
                  />
                )}
              />

              {/* Hidden Organization Role Field */}
              <Controller
                control={control}
                name="organization.role"
                render={({ field }) => (
                  <input
                    type="hidden"
                    {...field}
                    value={invitationData.organizationRole || ''}
                  />
                )}
              />
            </>
          )}

          <Controller
            rules={{
              required: true,
              validate: {
                hasLength: (v) => hasLength(v) || ERROR_MESSAGE,
                meetRequirements: (v) => validatePassword(v) || ERROR_MESSAGE,
                dontMatch: (value, formValues) =>
                  value === formValues.confirmPassword || ERROR_MESSAGE,
              },
            }}
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.password.label', 'Create password')}
                  <span className="text-danger">*</span>
                </label>
                <PasswordInput
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    'registration.password.placeholder',
                    'Create password',
                  )}
                />
              </div>
            )}
          />
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, value } }) => (
              <div className="position-relative mt-600">
                <label className="form-label">
                  {t('registration.confirm-password.label', 'Confirm password')}
                  <span className="text-danger">*</span>
                </label>
                <PasswordInput
                  value={value}
                  onChange={(value) => {
                    trigger('password')
                    return onChange(value)
                  }}
                  placeholder={t(
                    'registration.confirm-password.placeholder',
                    'Confirm password',
                  )}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="isTermsAccepted"
            rules={{ validate: (value) => value || ERROR_MESSAGE }}
            render={({ field: { onChange } }) => (
              <Form.Check
                defaultChecked={false}
                className="me-300 mt-600"
                id="accept.terms.conditions"
              >
                <Form.Check.Input
                  onChange={onChange}
                  data-testid="accept-terms"
                />
                <Form.Check.Label className="fw-semi-bold">
                  <Trans
                    i18nKey="accept.terms.conditions"
                    t={t}
                    defaults="I accept the <Link>Terms and Conditions</Link>"
                    components={{ Link: <Link to="/terms" target="_blank" /> }}
                  />
                  <span className="text-danger">*</span>
                </Form.Check.Label>
              </Form.Check>
            )}
          />
        </Form>
      </FormProvider>

      {password && (
        <PasswordRequirements
          isDirty={isDirty}
          passwordErrors={errors.password?.types}
          password={password}
        />
      )}

      <div className="d-grid w-100">
        <Button
          id="btn_register_register"
          isLoading={isPending}
          disabled={!isValid || !isDirty || isPending}
          onClick={handleSubmit(onSubmit)}
        >
          {t('common.signup', 'Sign Up')}
        </Button>
      </div>
      {/* {error && <Toast text={error} />} */}
      <LegalLinks />
    </AuthContainer>
  )
}
