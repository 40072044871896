import { Result, SummaryRequest, SummaryResult } from '../../types/domain'
import { useAiMutationPost } from '../Ai/useAiApiQuery/useAiApiQuery'

export const useSummarizeApi = () => {
  const mutation = useAiMutationPost<SummaryResult, Error, SummaryRequest>({
    path: '/summary'
  })

  return { ...mutation }
}
