import { useEffect, useState } from 'react'
import { Attachment } from '../../types/domain'
import { useAuth } from '../useAuth'
import {
  useAttachments,
  useDeleteAttachment,
  useUpsertAttachment,
} from './useAttachmentBase'

export const useAttachment = () => {
  const { user } = useAuth()
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const { data, isLoading, refetch, error } = useAttachments()
  const createAttachmentMutation = useUpsertAttachment()
  const deleteAttachmentMutation = useDeleteAttachment()

  useEffect(() => {
    if (data && user) {
      const filteredList = data.filter(
        (attachment) =>
          attachment.userId === user.id ||
          attachment.organizationId === user.organizationId ||
          (attachment.userId === null && attachment.organizationId === null),
      )
      setAttachments(filteredList)
    }
  }, [data, user])

  const upsertAttachment = async (newAttachmentData: Partial<Attachment>) => {
    try {
      const result =
        await createAttachmentMutation.mutateAsync(newAttachmentData)
      await refetch()
      return result
    } catch (error) {
      console.error('Error adding attachment:', error)
      throw error
    }
  }

  const deleteAttachment = async (id: string) => {
    try {
      const result = await deleteAttachmentMutation.mutateAsync({ id })
      await refetch()
      return result
    } catch (error) {
      console.error('Error deleting attachment:', error)
      throw error
    }
  }

  return {
    attachments,
    isLoading,
    error,
    addAttachment: upsertAttachment,
    deleteAttachment,
    refreshAttachments: refetch,
  }
}
