import normalizeUrl from 'normalize-url'
import { isURL } from 'validator';

export const validateAttachmentUrl = (url: string) => {
  try {
    let urlToTest = url
    if (!url.startsWith('http')) {
      urlToTest = getNormalizedUrl(url)
    }
    new URL(urlToTest) //throws when string passed is not valid URL
    return isURL(urlToTest)
  } catch {
    return false
  }
}

export const getNormalizedUrl = (url: string) => {
  const normalized = normalizeUrl(url, {
    defaultProtocol: 'http',
    stripWWW: false,    
  })
  return normalized
}
