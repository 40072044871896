import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import { useAttachment } from '../../../../hooks/useAttachment/useAttachment'
import { Template } from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import MarkdownEditor from '../../../core/MarkdownEditor/MarkdownEditor'
import { Select } from '../../../core/Select/Select'
import Modal from '../../../shared/Modal/Modal'
import {
  CategoryOption,
  CategoryOptions,
  useCategories,
  useCategoryOptions,
  useSubcategoryOptions,
} from '../../InteractionCategory/CategoryOptions'
import styles from '../../InteractionSummary/Modal.module.scss'
import { AtachmentOption } from '../AttachmentManagement/AttachmentOption'
import useLibraryStore from '../store'
import { ValidationError } from '../types'

interface ExtendedTemplate {
  id?: string
  displayName: string
  content: string
  category: CategoryOption[]
  subcategory: CategoryOption[]
  attachments: AtachmentOption[]
  isForOrganization?: boolean
  userId: string | null
  organizationId: string | null
}

interface TemplateManagementModalProps {
  show: boolean
  onHide: () => void
  template: Template | null
  onSubmit: (data: Partial<Template>) => Promise<void | ValidationError>
  isManager: boolean
}

export const mapFormDataToApiFormat = (
  formData: Partial<ExtendedTemplate>,
): Partial<Template> => {
  const apiData: Partial<Template> = {
    id: formData.id,
    displayName: formData.displayName?.trim(),
    content: formData.content,
    category: formData.category?.length
      ? formData.category.map((cat) => cat.value)
      : [],
    subcategory: formData.subcategory?.length
      ? formData.subcategory.map((sub) => sub.value)
      : [],
    attachmentIds: formData.attachments?.length
      ? formData.attachments.map((att) => att.value)
      : [],
  }
  return apiData
}

const TemplateManagementModal: React.FC<TemplateManagementModalProps> = ({
  show,
  onHide,
  template,
  onSubmit,
  isManager,
}) => {
  const { t } = useTranslation()
  const isSaving = useLibraryStore((state) => state.isSaving)
  const setIsSaving = useLibraryStore((state) => state.setIsSaving)
  const featureFlags = useLibraryStore((state) => state.featureFlags)
  const [isNew, setIsNew] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [contentHasText, setContentHasText] = useState(false)
  const [formData, setFormData] = useState<Partial<ExtendedTemplate>>({
    id: uuidv4(),
    displayName: '',
    content: '',
    category: [],
    subcategory: [],
    attachments: [],
    isForOrganization: false,
  })
  const categoryOptions = useCategoryOptions()
  const subcategoryOptions = useSubcategoryOptions(formData.category || null)
  const allCategories = useCategories()
  const { attachments } = useAttachment()
  const attachmentOptions: AtachmentOption[] = attachments.map(
    (attachment) => ({
      value: attachment.id,
      label: attachment.displayName,
    }),
  )

  useEffect(() => {
    if (show && template) {
      const newData = mapApiToFormData(template)
      setFormData(newData)
      setErrors({})
      setIsNew(false)
    } else if (show) {
      setFormData({
        id: uuidv4(),
        displayName: '',
        content: '',
        category: [],
        subcategory: [],
        attachments: [],
        isForOrganization: isManager,
      })
      setErrors({})
      setIsNew(true)
    }
  }, [show, template?.id, isManager, allCategories])

  const isFormValid = useMemo(() => {
    return (
      contentHasText &&
      !!(formData.displayName?.trim() && formData.content?.trim())
    )
  }, [formData.displayName, formData.content, contentHasText])

  const handleSubmit = async () => {
    if (!isFormValid || isSaving) return

    try {
      setIsSaving(true)
      const apiData = mapFormDataToApiFormat(formData)
      const validationError = await onSubmit(apiData)
      console.log(validationError)
      if (validationError) {
        setErrors(validationError.details)
      }
    } catch (error) {
      console.error('Error saving template:', error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleClose = () => {
    onHide()
    setFormData({})
    setIsNew(true)
  }

  const handleCategoryChange = (values: MultiValue<CategoryOption>) => {
    const newCategories = Array.from(values)
    const newCategoryIds = new Set(newCategories.map((cat) => cat.value))

    // Keep existing subcategories that still have their parent category selected
    const updatedSubcategories = formData.subcategory?.filter((subcat) => {
      // Find the parent category for this subcategory
      const subcategoryData = CategoryOptions.find(
        (cat) => cat.id === subcat.value,
      )
      if (!subcategoryData) return false

      // Check if the parent category is still selected
      return newCategoryIds.has(subcategoryData.parentId as number)
    })

    setFormData((prev) => ({
      ...prev,
      category: newCategories,
      subcategory: updatedSubcategories || [],
    }))
  }

  const handleSubcategoryChange = (values: MultiValue<CategoryOption>) => {
    setFormData((prev) => ({ ...prev, subcategory: Array.from(values) }))
  }

  const handleAttachmentsChange = (values: MultiValue<AtachmentOption>) => {
    setFormData((prev) => ({ ...prev, attachments: Array.from(values) }))
  }

  const mapApiToFormData = (template: Template): Partial<ExtendedTemplate> => {
    const categories =
      template.category?.map((value) => ({
        value,
        label:
          allCategories.find((cat) => cat.id === value)?.label ||
          value.toString(),
      })) || []

    const subcategories =
      template.subcategory?.map((value) => ({
        value,
        label:
          allCategories.find((cat) => cat.id === value)?.label ||
          value.toString(),
      })) || []

    return {
      ...template,
      displayName: template.displayName || '',
      content: template.content || '',
      category: categories,
      subcategory: subcategories,
      attachments:
        template.attachments?.map((attachment) => ({
          value: attachment.id,
          label: attachment.displayName,
        })) || [],
      isForOrganization: template.organizationId !== null,
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <div className={`w-100 ${styles.titleContainer}`}>
        <h3 className={styles.title}>
          {template
            ? t('library.template.modal.title-edit', 'Edit Template')
            : t('library.template.modal.title-add', 'Add Template')}
        </h3>
      </div>
      <div className={styles.bodyContainer}>
        <Form.Group>
          <Form.Label>
            {t('library.template.modal.display-name.label', 'Display Name')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            className="input-lg"
            value={formData.displayName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData((prev) => ({ ...prev, displayName: e.target.value }))
              setErrors({}) //if another validation added, change this
            }}
            placeholder={t(
              'library.template.modal.display-name.placeholder',
              'Enter template display name',
            )}
            required
            isInvalid={!!errors.display_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.display_name &&
              t(
                'library.template.modal.display-name.not-unique',
                'Please enter unique name',
              )}
          </Form.Control.Feedback>
        </Form.Group>
        {featureFlags.isCategoryEnabled && (
          <>
            <Form.Group className="mt-400">
              <Form.Label>
                {t('library.template.modal.categories.label', 'Categories')}
              </Form.Label>
              <Select<CategoryOption, true>
                isMulti
                options={categoryOptions}
                value={formData.category}
                onChange={handleCategoryChange}
              />
            </Form.Group>

            {(formData.category?.length ?? 0) > 0 && (
              <Form.Group className="mt-400">
                <Form.Label>
                  {t(
                    'library.template.modal.subcategories.label',
                    'Subcategories',
                  )}
                </Form.Label>
                <Select<CategoryOption, true>
                  isMulti
                  options={subcategoryOptions}
                  value={formData.subcategory}
                  onChange={handleSubcategoryChange}
                />
              </Form.Group>
            )}
          </>
        )}
        <Form.Group className="mt-400">
          <Form.Label>
            {t('library.template.modal.content.label', 'Content')}
            <span className="text-danger">*</span>
          </Form.Label>
          <MarkdownEditor
            value={formData.content}
            onChange={(value) => {
              setFormData((prev) => ({ ...prev, content: value.markdown }))
              setContentHasText(value.hasNonWhitespaceText ?? false)
            }}
            placeholder={t(
              'library.template.modal.content.placeholder',
              'Enter content',
            )}
          />
        </Form.Group>

        <Form.Group className="mt-400">
          <Form.Label>
            {t('library.template.modal.attachments.label', 'Attachments')}
          </Form.Label>
          <Select<AtachmentOption, true>
            isMulti
            options={attachmentOptions}
            value={formData.attachments}
            onChange={handleAttachmentsChange}
            placeholder={t('common.select.placeholder', 'Select...')}
          />
        </Form.Group>

        {isManager && (
          <div className="form-group mt-400">
            <label>
              <input
                type="checkbox"
                checked={formData.isForOrganization}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    isForOrganization: e.target.checked,
                  }))
                }
              />
              {t(
                'library.template.modal.use-organization-template',
                'Use Organization Template',
              )}
            </label>
          </div>
        )}
      </div>
      <div
        className={`d-flex justify-content-end gap-2 ${styles.footerContainer}`}
      >
        <Button variant="link" onClick={handleClose} className={styles.button}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button
          className="btn-primary"
          size="sm"
          disabled={!isFormValid || isSaving}
          onClick={handleSubmit}
        >
          {isNew
            ? t('common.save.new', 'Save')
            : t('common.save.changes', 'Save')}
        </Button>
      </div>
    </Modal>
  )
}

export default TemplateManagementModal
