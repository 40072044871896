import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import useStore from '../store'
import { useTranslation } from 'react-i18next'

interface AudioVisualizerProps {
  isRecording: boolean
}

const AudioVisualizerSpeed: React.FC<AudioVisualizerProps> = ({
  isRecording,
}) => {
  const { t } = useTranslation()
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)
  const audioContextRef = useRef<AudioContext | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const audioNodeRef = useRef<AudioNode | null>(null)
  const intervalIdRef = useRef<any>() // Amplitude tracker
  const deviceId = useStore((state) => state.deviceId)

  const startRecording = async () => {
    try {
      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      analyser.fftSize = 256

      const constraints: MediaStreamConstraints = {
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      mediaStreamRef.current?.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, state: ${track.readyState}`)
        track.stop()
      })
      mediaStreamRef.current = mediaStream

      const source = audioContext.createMediaStreamSource(mediaStream)
      audioNodeRef.current = source.connect(analyser)

      audioContextRef.current = audioContext
      analyserRef.current = analyser
      dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount)
      //
      intervalIdRef.current = setInterval(() => {
        if (analyserRef.current && dataArrayRef.current && videoRef.current) {
          analyserRef.current.getByteTimeDomainData(dataArrayRef.current)

          // Calculate the average amplitude
          const amplitude =
            dataArrayRef.current.reduce(
              (sum, val) => sum + Math.abs(val - 128),
              0,
            ) / dataArrayRef.current.length

          // Map amplitude to playback speed (adjust range as needed)
          const playbackSpeed = Math.min(Math.max(0.5, amplitude / 4), 16) // Speed between 0.5x and 5x
          videoRef.current.playbackRate = playbackSpeed
          try {
            videoRef.current.play()
          } catch (e) {
            console.log(e)
          }
          //console.log(playbackSpeed)
        }
      }, 500)
      //
    } catch (error) {
      console.error(
        t(
          'consult.error.starting-recording',
          'Visualizer: Error starting recording:',
        ),
        error,
      )
    }
  }

  const stopRecording = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, state: ${track.readyState}`)
        track.stop()
      })
      mediaStreamRef.current = null
    } else {
      console.log(
        t(
          'consult.error.no-active-media-stream',
          'No active MediaStream to stop.',
        ),
      )
    }

    if (audioNodeRef.current) {
      audioNodeRef.current.disconnect()
      audioNodeRef.current = null
    } else {
      console.log(
        t(
          'consult.error.no-active-audio-node',
          'No active audio node to disconnect.',
        ),
      )
    }

    if (audioContextRef.current) {
      audioContextRef.current
        .close()
        .then(() => {
          console.log(
            t(
              'consult.audio-context-closed-successfully',
              'AudioContext closed successfully.',
            ),
          )
        })
        .catch((err) => {
          console.error(
            t(
              'consult.error.closing-audio-context',
              'Error closing AudioContext:',
            ),
            err,
          )
        })
      audioContextRef.current = null
    } else {
      console.log(
        t(
          'consult.error.no-active-audio-context',
          'No active AudioContext to close.',
        ),
      )
    }

    console.log(
      t(
        'consult.recording-stopped-successfully',
        'Recording stopped successfully.',
      ),
    )
  }

  useEffect(() => {
    if (isRecording) {
      startRecording()
    }

    return () => {
      stopRecording() // Ensure cleanup is called on unmount
    }
  }, [isRecording, deviceId])

  return (
    <div>
      <video
        ref={videoRef}
        src="/media/recordinglogo.mp4"
        autoPlay={true}
        style={{ width: '400px' }}
      />
    </div>
  )
}

export default AudioVisualizerSpeed
