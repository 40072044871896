import React from 'react'
import styles from './ActionButton.module.scss'
import { ActionButtonProps } from './types'

export const ActionButton: React.FC<ActionButtonProps> = ({
  label,
  icon,
  variant = 'secondary',
  onClick,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${styles[variant]}`}
      onClick={onClick}
    >
      <span className={styles.label}>{label}</span>
      {icon && <img loading="lazy" src={icon} alt="" className={styles.icon} />}
    </button>
  )
}
