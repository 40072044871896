import { create } from 'zustand'

interface ToastState {
  show: boolean
  variant: 'success' | 'danger' | string
  message: string
}

interface ToastStore {
  toast: ToastState
  showToast: (variant: ToastState['variant'], message: string) => void
  hideToast: () => void
}

const toastTimeout = 3000

const useToast = create<ToastStore>((set) => ({
  toast: {
    show: false,
    variant: '',
    message: '',
  },
  showToast: (variant, message) => {
    set((state) => ({
      ...state,
      toast: {
        show: true,
        variant,
        message,
      },
    }))

    setTimeout(() => {
      set((state) => ({
        ...state,
        toast: {
          show: false,
          variant: '',
          message: '',
        },
      }))
    }, toastTimeout)
  },

  hideToast: () =>
    set((state) => ({
      ...state,
      toast: {
        show: false,
        variant: '',
        message: '',
      },
    })),
}))

export default useToast