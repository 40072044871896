import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useUpsertOrganization } from '../../../hooks/useOrganization/useOrganization'
import Button from '../../core/Button/Button'
import { Toast } from '../../core/Toast/Toast'

const OrganizationRegistration: React.FC = () => {
  const { user } = useAuth()
  const [orgName, setOrgName] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mutate: createOrganization } = useUpsertOrganization()
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleRegisterOrganization = async () => {
    if (!user?.id) {
      setToastConfig({
        show: true,
        variant: 'danger',
        message: t(
          'organization.create.error.auth',
          'User is not authenticated',
        ),
      })
      return
    }

    const newOrganization = {
      name: orgName,
      ownerId: user.id,
      members: [{ userId: user.id, role: 'owner' }],
    }

    createOrganization(newOrganization, {
      onSuccess: () => {
        setToastConfig({
          show: true,
          variant: 'success',
          message: t(
            'organization.create.success',
            'Organization created successfully',
          ),
        })
        navigate('/account/organization')
      },
      onError: (error) => {
        console.error('Error creating organization:', error)
        setToastConfig({
          show: true,
          variant: 'danger',
          message: t(
            'organization.create.error',
            'Failed to create organization',
          ),
        })
      },
    })
  }

  return (
    <div className="max-w-800px">
      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
      <h1 className="h1 mb-500">
        {t('organization.register.title', 'Register Organization')}
      </h1>
      <div className="mb-700">
        <Form.Group className="mb-500">
          <Form.Label>
            {t('organization.form.name', 'Organization Name')}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              'organization.form.name.placeholder',
              'Enter organization name',
            )}
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={handleRegisterOrganization}
          disabled={!orgName.trim()}
        >
          {t('organization.register.button', 'Register Organization')}
        </Button>
      </div>
    </div>
  )
}

export default OrganizationRegistration
