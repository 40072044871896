export const mockAiApiReponse = {
  title: '',
  transcript_raw: {
    segments: [
      {
        text: 'Goedemorgen mevrouw Janssen. Fijn dat u er bent. Waar kan ik u vandaag mee helpen?',
        speaker: 0,
        timestamp: 0.0,
      },
      {
        text: 'Goedemorgen dokter. Ik ben de laatste tijd ontzettend moe. Ik krijg mijn energie gewoon niet meer terug hoe lang ik ook sla. En daarnaast doen mijn gewichten steeds vaker pijn en van volwassen.',
        speaker: 1,
        timestamp: 10.639999,
      },
      {
        text: 'Dat klinkt vervelend. U bent hier eerder geweest voor vermoeidheid toch?',
        speaker: 0,
        timestamp: 22.555,
      },
      {
        text: 'Ja, vorig jaar ook al. Toen werd ik naar de fysiotherapeut verwezen voor mijn rug. Maar die vermoeidheid lijkt nu erger.',
        speaker: 1,
        timestamp: 26.395,
      },
      {
        text: 'Goed dat u het aangeeft. Heeft u nu last van andere klachten zoals koorts, nachtelijk zweten of gewichtsverlies?',
        speaker: 0,
        timestamp: 33.36,
      },
      {
        text: 'Nee, geen koortsverlies. Maar ik heb wel tintelingen in mijn handen en mijn knieën vullen wat opgeslokt.',
        speaker: 1,
        timestamp: 38.72,
      },
      {
        text: 'Ik begrijp het. Kunt u mij vertellen welke medicijnen ik momenteel gebruikt?',
        speaker: 0,
        timestamp: 44.879997,
      },
      {
        text: 'Natuurlijk. Neem elke dag vijfenzeventig milligram levotirooxine voor mijn schildklier en daarnaast tien milligram syfostatine tegen mijn cholesterol. En zo nodig gebruik ik iibopven voor de kleine in mijn gewrichten maar dat probeer ik te vermijden.',
        speaker: 1,
        timestamp: 48.435,
      },
      {
        text: 'Dankjewel. Levertyloxine voor de schildklier en Simfastatine voor het cholesterol. Weten u toevallig of uw schildklierwaarden ondanks nog gecontroleerd zijn?',
        speaker: 0,
        timestamp: 62.53,
      },
      {
        text: 'Ja dat was tien maanden geleden. De waarde waren toen stabiel zijn jullie.',
        speaker: 1,
        timestamp: 71.815,
      },
      {
        text: 'Goed dat u dat weet. Het kan soms gebeuren dat ondanks stabiele voorwaarden schildklierhormonen toch minder effectief worden in de loop der tijd. Dat kan vermoeidheid en zelfs gewrichtsklachten veroorzaken. Ik stel voor dat we opnieuw bloed laten onderzoeken door een schildklierfunctie om naar schildklierfunctie te kijken en naar andere dingen te controleren zoals ontstekingswaardes en vitamines.',
        speaker: 0,
        timestamp: 76.055,
      },
      {
        text: 'Dat klinkt goed. Ik wil graag weten of er iets mis is.',
        speaker: 1,
        timestamp: 97.87,
      },
      {
        text: 'Zeker dat snap ik. Heeft u nog vragen of andere zorgen?',
        speaker: 0,
        timestamp: 101.165,
      },
      {
        text: 'Nou zouden IBen misschien de vermoeidheid kunnen verergeren?',
        speaker: 1,
        timestamp: 104.205,
      },
      {
        text: "In sommige gevallen kan regelmatig het gebruik van NSAID's zoals IBen inderdaad bijwerking veroorzaken zoals maagklachten en soms ook vermoeidheid. We kunnen kijken of er alternatieven zijn voor pijnbestrijding mocht dat nodig lijken.",
        speaker: 0,
        timestamp: 107.805,
      },
      {
        text: 'Oké, dat lijkt me verstandig.',
        speaker: 1,
        timestamp: 121.130005,
      },
      {
        text: 'Prima, dan maken we vandaag een afspraak bij de laboratoria voor een bloedonderzoek en bel ik u binnen een paar dagen met de uitslag. We kunnen daarna samen verder kijken.',
        speaker: 0,
        timestamp: 123.05,
      },
      {
        text: 'Dankjewel, oké. Dat geeft me wel met rust.',
        speaker: 1,
        timestamp: 131.49501,
      },
      {
        text: 'Graag gedaan van Janssen. Je bent blij dat het lastig is. We houden de vinger aan de pols.',
        speaker: 0,
        timestamp: 133.895,
      },
    ],
  },
  transcript_anonymized: {
    segments: [
      {
        text: 'Goedemorgen mevrouw <PERSON>. Fijn dat u er bent. Waar kan ik u vandaag mee helpen?',
        speaker: 0,
        timestamp: 0.0,
      },
      {
        text: 'Goedemorgen dokter. Ik <PERSON> tijd ontzettend moe. Ik krijg mijn energie gewoon niet meer terug hoe lang ik ook sla. En daarnaast doen mijn gewichten steeds vaker pijn en van volwassen.',
        speaker: 1,
        timestamp: 10.639999,
      },
      {
        text: 'Dat klinkt vervelend. U bent hier eerder geweest voor vermoeidheid toch?',
        speaker: 0,
        timestamp: 22.555,
      },
      {
        text: 'Ja, <DATE_TIME> ook al. Toen werd ik naar de fysiotherapeut verwezen voor mijn rug. Maar die vermoeidheid lijkt nu erger.',
        speaker: 1,
        timestamp: 26.395,
      },
      {
        text: 'Goed dat u het aangeeft. Heeft u nu last van andere klachten zoals koorts, nachtelijk zweten of gewichtsverlies?',
        speaker: 0,
        timestamp: 33.36,
      },
      {
        text: 'Nee, geen koortsverlies. Maar ik heb wel tintelingen in mijn handen en mijn knieën vullen wat opgeslokt.',
        speaker: 1,
        timestamp: 38.72,
      },
      {
        text: 'Ik begrijp het. Kunt u mij vertellen welke medicijnen ik momenteel gebruikt?',
        speaker: 0,
        timestamp: 44.879997,
      },
      {
        text: '<LOCATION>. Neem elke dag vijfenzeventig <PERSON> en daarnaast tien milligram syfostatine tegen mijn cholesterol. En zo nodig gebruik ik iibopven voor de kleine in mijn gewrichten maar dat probeer ik te vermijden.',
        speaker: 1,
        timestamp: 48.435,
      },
      {
        text: 'Dankjewel. <PERSON> voor de schildklier en Simfastatine voor het cholesterol. Weten u toevallig of uw schildklierwaarden ondanks nog gecontroleerd zijn?',
        speaker: 0,
        timestamp: 62.53,
      },
      {
        text: 'Ja dat was <DATE_TIME> geleden. De waarde waren toen stabiel zijn jullie.',
        speaker: 1,
        timestamp: 71.815,
      },
      {
        text: 'Goed dat u dat weet. Het kan soms gebeuren dat ondanks stabiele voorwaarden schildklierhormonen toch minder effectief worden in de loop der tijd. Dat kan vermoeidheid en zelfs gewrichtsklachten veroorzaken. Ik stel voor dat we opnieuw bloed laten onderzoeken door een schildklierfunctie om naar schildklierfunctie te kijken en naar andere dingen te controleren zoals ontstekingswaardes en vitamines.',
        speaker: 0,
        timestamp: 76.055,
      },
      {
        text: 'Dat klinkt goed. Ik wil graag weten of er iets mis is.',
        speaker: 1,
        timestamp: 97.87,
      },
      {
        text: 'Zeker dat snap ik. Heeft u nog vragen of andere zorgen?',
        speaker: 0,
        timestamp: 101.165,
      },
      {
        text: 'Nou zouden IBen misschien de vermoeidheid kunnen verergeren?',
        speaker: 1,
        timestamp: 104.205,
      },
      {
        text: "In sommige gevallen kan regelmatig het gebruik van NSAID's zoals IBen inderdaad bijwerking veroorzaken zoals maagklachten en soms ook vermoeidheid. We kunnen kijken of er alternatieven zijn voor pijnbestrijding mocht dat nodig lijken.",
        speaker: 0,
        timestamp: 107.805,
      },
      {
        text: 'Oké, dat lijkt me verstandig.',
        speaker: 1,
        timestamp: 121.130005,
      },
      {
        text: 'Prima, dan maken we vandaag een afspraak bij de <PERSON> voor een bloedonderzoek en <NRP> ik u binnen <DATE_TIME> met de uitslag. We kunnen daarna samen verder kijken.',
        speaker: 0,
        timestamp: 123.05,
      },
      {
        text: 'Dankjewel, oké. Dat geeft me wel met rust.',
        speaker: 1,
        timestamp: 131.49501,
      },
      {
        text: 'Graag gedaan van <PERSON>. Je bent blij dat het lastig is. We houden de vinger aan de pols.',
        speaker: 0,
        timestamp: 133.895,
      },
    ],
  },
  summaries: [
    {
      protocol_name: 'SOAP',
      content: [
        {
          field_name: 'S',
          content:
            'Aanhoudende vermoeidheid, onvermogen om energie terug te krijgen ondanks voldoende slaap, toenemende pijn in gewrichten, eerdere behandeling voor vermoeidheid en rugklachten, geen koorts, geen nachtelijk zweten, geen gewichtsverlies, tintelingen in handen, opgezette knieën',
        },
        {
          field_name: 'O',
          content: 'Niet gespecificeerd',
        },
        {
          field_name: 'A',
          content:
            'Vermoeidheid, mogelijk gerelateerd aan schildklierproblemen en/of gewrichtsaandoening',
        },
        {
          field_name: 'P',
          content:
            'Bloedonderzoek voor schildklierfunctie, controle van ontstekingswaardes en vitamines, mogelijke aanpassing van pijnbestrijdingsmedicatie, follow-up na ontvangst van bloedonderzoeksresultaten',
        },
      ],
    },
    {
      protocol_name: 'PAT',
      content: [
        {
          field_name: 'SUMMARY',
          content:
            'De patiënt ervaart aanhoudende vermoeidheid, onvermogen om energie terug te krijgen ondanks voldoende slaap, toenemende pijn in gewrichten, eerdere behandeling voor vermoeidheid en rugklachten, geen koorts, geen nachtelijk zweten, geen gewichtsverlies, tintelingen in handen, en opgezette knieën. De arts plant bloedonderzoek voor schildklierfunctie, controle van ontstekingswaardes en vitamines, en overweegt een mogelijke aanpassing van pijnbestrijdingsmedicatie na ontvangst van de bloedonderzoeksresultaten.',
        },
      ],
    },
  ],
}
