import React from 'react'

interface UrlMetadata {
  title?: string
  description?: string
  domain?: string
  img?: string
  favicon?: string
}

interface AttachmentPreviewProps {
  metadata?: UrlMetadata
  size?: 'normal' | 'small'
}

export const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
  metadata,
  size = 'normal',
}) => {
  return (
    <div
      className={`attachment-preview ${size === 'small' ? 'attachment-preview--small' : ''}`}
    >
      <div className="attachment-preview__thumbnail">
        {metadata?.img ? (
          <img src={metadata.img} alt={metadata.title} />
        ) : (
          <img src="media/unknown-url.svg" alt="Unknown URL" />
          // <div className="attachment-preview__thumbnail--placeholder">
          //   {/*  */}
          // </div>
        )}
      </div>
      <div className="attachment-preview__info">
        <div className="attachment-preview__title">{metadata?.title}</div>
        {/*<div className="attachment-preview__description">
          {metadata?.description}
          {metadata?.domain && (
          <div className="attachment-preview__domain">
            {metadata.favicon && (
              <img
                src={metadata.favicon}
                alt={metadata.domain}
                className="attachment-preview__favicon"
              />
            )}
            <span>{metadata.domain}</span>
            </div>
          )}
        </div>*/}
      </div>
    </div>
  )
}
