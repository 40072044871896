import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

// Complete base structure with all categories
export const CategoryOptionsBase = [
  {
    id: 0,
    key: 'category.patient-care-services',
    defaultLabel: 'Patient Care Services',
    parentId: null,
  },
  {
    id: 1,
    key: 'category.primary-care',
    defaultLabel: 'Primary Care',
    parentId: 0,
  },
  {
    id: 2,
    key: 'category.specialty-care',
    defaultLabel: 'Specialty Care',
    parentId: 0,
  },
  {
    id: 3,
    key: 'category.emergency-services',
    defaultLabel: 'Emergency Services',
    parentId: 0,
  },
  {
    id: 4,
    key: 'category.palliative-care',
    defaultLabel: 'Palliative Care',
    parentId: 0,
  },
  {
    id: 5,
    key: 'category.diagnostic-services',
    defaultLabel: 'Diagnostic Services',
    parentId: null,
  },
  {
    id: 6,
    key: 'category.laboratory',
    defaultLabel: 'Laboratory',
    parentId: 5,
  },
  {
    id: 7,
    key: 'category.imaging',
    defaultLabel: 'Imaging',
    parentId: 5,
  },
  {
    id: 8,
    key: 'category.genetic-testing',
    defaultLabel: 'Genetic Testing',
    parentId: 5,
  },
  {
    id: 9,
    key: 'category.surgical-services',
    defaultLabel: 'Surgical Services',
    parentId: null,
  },
  {
    id: 10,
    key: 'category.elective-surgery',
    defaultLabel: 'Elective Surgery',
    parentId: 9,
  },
  {
    id: 11,
    key: 'category.emergency-surgery',
    defaultLabel: 'Emergency Surgery',
    parentId: 9,
  },
  {
    id: 12,
    key: 'category.minimally-invasive',
    defaultLabel: 'Minimally Invasive Surgery',
    parentId: 9,
  },
  {
    id: 13,
    key: 'category.mental-health',
    defaultLabel: 'Mental Health Services',
    parentId: null,
  },
  {
    id: 14,
    key: 'category.counseling',
    defaultLabel: 'Counseling',
    parentId: 13,
  },
  {
    id: 15,
    key: 'category.psychiatric-services',
    defaultLabel: 'Psychiatric Services',
    parentId: 13,
  },
  {
    id: 16,
    key: 'category.substance-abuse',
    defaultLabel: 'Substance Abuse Services',
    parentId: 13,
  },
  {
    id: 17,
    key: 'category.rehabilitation',
    defaultLabel: 'Rehabilitation Services',
    parentId: null,
  },
  {
    id: 18,
    key: 'category.physical-therapy',
    defaultLabel: 'Physical Therapy',
    parentId: 17,
  },
  {
    id: 19,
    key: 'category.occupational-therapy',
    defaultLabel: 'Occupational Therapy',
    parentId: 17,
  },
  {
    id: 20,
    key: 'category.speech-therapy',
    defaultLabel: 'Speech Therapy',
    parentId: 17,
  },
  {
    id: 21,
    key: 'category.preventive-services',
    defaultLabel: 'Preventive Services',
    parentId: null,
  },
  {
    id: 22,
    key: 'category.screenings',
    defaultLabel: 'Screenings',
    parentId: 21,
  },
  {
    id: 23,
    key: 'category.vaccinations',
    defaultLabel: 'Vaccinations',
    parentId: 21,
  },
  {
    id: 24,
    key: 'category.health-education',
    defaultLabel: 'Health Education',
    parentId: 21,
  },
] as const

export const CategoryOptions = CategoryOptionsBase.map(cat => ({
  id: cat.id,
  label: cat.defaultLabel,
  parentId: cat.parentId,
}))

export const useCategories = () => {
  const { t } = useTranslation()
  
  return useMemo(() => {
    return CategoryOptionsBase.map(cat => ({
      id: cat.id,
      label: t(cat.key, cat.defaultLabel),
      parentId: cat.parentId,
    }))
  }, [t])
}

export const categoryOptions: CategoryOption[] = CategoryOptionsBase.filter(
  (option) => option.parentId === null,
).map((option) => ({
  value: option.id,
  label: option.defaultLabel,
}))

export const useCategoryOptions = () => {
  const categories = useCategories()
  
  return useMemo(() => {
    return categories
      .filter(option => option.parentId === null)
      .map(option => ({
        value: option.id,
        label: option.label,
      }))
  }, [categories])
}

export const useSubcategoryOptions = (parentCategories: CategoryOption[] | null) => {
  const categories = useCategories()
  
  return useMemo(() => {
    if (!parentCategories?.length) return []
    
    const parentIds = parentCategories.map(cat => cat.value)
    return categories
      .filter(option => option.parentId !== null && parentIds.includes(option.parentId))
      .map(option => ({
        value: option.id,
        label: option.label,
      }))
  }, [categories, parentCategories])
}

export interface CategoryOption {
  value: number
  label: string
}

type CategoryId = number
type SubcategoryId = number

export const useCategoryLabels = (categoryIds: CategoryId[]) => {
  const categories = useCategories()
  
  return useMemo(() => {
    return categoryIds
      .map(id => {
        const category = categories.find(cat => cat.id === id)
        return category ? category.label : id.toString()
      })
      .join(', ')
  }, [categories, categoryIds])
}
